class DistortionFilter {
    constructor(audioCtx, wetDryMix = 0.0, freq = 20000) {
        this._wetDryMix = wetDryMix;
        this._freq = freq;

        this.inputGain = audioCtx.createGain();
        this.bpf = audioCtx.createBiquadFilter();

        this.bpf.type = "lowpass";
        this.bpf.Q.value = 1.0;
        this.bpf.frequency.value = this._freq;
        this.minFreq = 150;
        this.maxFreq = 20000;

        this.distortNode = audioCtx.createWaveShaper();
        this.distortGain = audioCtx.createGain();

        this.passGain = audioCtx.createGain();
        this.output = audioCtx.createGain();

        let value = 200;
        let samples = 44100;
        let curve = new Float32Array(samples);
        let deg = Math.PI / 180;
        let i = 0;
        let x;

        for (; i < samples; ++i) {
            x = i * 2 / samples - 1;
            curve[i] = (3 + value) * x * 20 * deg / (Math.PI + value * Math.abs(x));
        }

        this.distortNode.curve = curve;
        this.distortNode.oversample = '4x';

        this.inputGain.connect(this.bpf);
        this.bpf.connect(this.distortNode);
        this.distortNode.connect(this.distortGain);
        this.distortGain.connect(this.output);

        this.inputGain.connect(this.passGain);
        this.passGain.connect(this.output);

        this.passGain.gain.value = 1 - this._wetDryMix;
        this.distortGain.gain.value = this._wetDryMix;

    }
    get source() {
        return this.inputGain;
    }
    get destination() {
        return this.output;
    }

    get wetDryMix() {
        return this._wetDryMix;
    }
    set wetDryMix(value) {
        this._wetDryMix = value;
        this.passGain.gain.value = 1 - value;
        this.distortGain.gain.value = value * 0.65;
    }

    get freq() {
        return this._freq;
    }

    set freq(value) {
        this._freq = value;
        this.bpf.frequency.value = value;
    }

    set x(value) {
        this.wetDryMix = value;
    }

    set y(value) {
        this.freq = (Math.pow(value, 5) * (this.maxFreq - this.minFreq)) + this.minFreq;
    }

    getDefaultPosition() {
        return {x: 0.0, y: 1.0};
    }

    clear() {
        this.wetDryMix = 0.0;
        this.freq = this.maxFreq;
    }
}


export default DistortionFilter;
