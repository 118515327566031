import React, {useEffect, useRef, useState} from 'react';
import {Box} from "@material-ui/core";
import {Container} from "@material-ui/core";

import '../LayoutComps/muiLayoutStyle.css';

import axios from 'axios';

import MdBottomNav from "../LayoutComps/MuiLayoutComps/MdBottomNav";
import MdDrumpadUi from "../LayoutComps/MuiLayoutComps/MdDrumPadUi";
import MdSequencerUi from "../LayoutComps/MuiLayoutComps/MdSequencerUi";
import MdMixerUi from "../LayoutComps/MuiLayoutComps/MdMixerUi";
import MdEffectsUi from "../LayoutComps/MuiLayoutComps/MdEffectsUi";
import MdPackSelector from "../LayoutComps/MuiLayoutComps/MdPackSelector";
import MdPlayControls from "../LayoutComps/MuiLayoutComps/MdPlayControls2";
import MdSettingsUi from "../LayoutComps/MuiLayoutComps/MdSettingsUi";

import MdEffectsSelector from "../LayoutComps/MuiLayoutComps/MdEffectsSelector";

import {createPackMixerState} from "../LayoutComps/MuiLayoutComps/MdMakeMixerStateDummy";
import MuiLayoutStyles from "../LayoutComps/MuiLayoutComps/MuiLayoutStyles";

import MdEventEmitter from "../LayoutComps/MdEventEmitter";
import MdMetronome from "./WebAudio/MdMetronome";

import config from "./Configs/config";
import {PIXI} from "./Configs/PixiLib";
import {makeEmptyPack} from "./CompConstructors";

//VFX 1
import {AdjustmentFilter} from '@pixi/filter-adjustment';
import {RGBSplitFilter} from '@pixi/filter-rgb-split';

//VFX 2
import {KawaseBlurFilter} from '@pixi/filter-kawase-blur';

//VFX 3
import DisplacementSprite from './VfxFilters/DisplacementSprites/blink_displacemap.jpg'
// import DisplacementSprite from './VfxFilters/DisplacementSprites/Cacti_Displacement_1.jpg';
import {ZoomBlurFilter} from '@pixi/filter-zoom-blur';

//VFX 4
import {PixelateWithMixFilter} from "./VfxFilters/MdPixelate/PixelateWithMixFilter";


import { Snackbar } from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';

import DelayFilter from "./SfxFilters/DelayFilter";
import TestFilter from "./SfxFilters/TestFilter";
import ReverbFilter from "./SfxFilters/ReverbFilter";
import DistortionFilter from "./SfxFilters/DistortionFilter";
import {parse} from "@fortawesome/fontawesome-svg-core";

export const getAnimIdx = (a) => parseInt(a.textureCacheIds[0].split('.')[0].split("_")[1]);

let getSpritesheetAddress;
let getAudioRefsAddress;
let packRefPrefix;


if (process.env.NODE_ENV === 'development') {
    getSpritesheetAddress = (a) => 'http://' + config.server_ip + '/media/PREPPED/' + a;
    getAudioRefsAddress = (a) => 'http://' + config.server_ip + '/media/PREPPED/' + a;
    packRefPrefix = 'http://' + config.server_ip;
} else {
    getSpritesheetAddress = (a) => '/media/PREPPED/' + a;
    getAudioRefsAddress = (a) => '/media/PREPPED/' + a;
    packRefPrefix = '';
}

const createInitComp = () => {
    console.log("CREATE INIT COMP CALLED");
    return {
        0: {...makeEmptyPack()},
        1: {...makeEmptyPack()},
        2: {...makeEmptyPack()},
    }
}

const getDeviceData = () => {
    let deviceData = {};

    try {
        deviceData['appCodeName'] = navigator.appCodeName;
        deviceData['appName'] = navigator.appName;
        deviceData['appVersion'] = navigator.appVersion;
        deviceData['language'] = navigator.language;
        deviceData['platform'] = navigator.platform;
        deviceData['product'] = navigator.product;
        deviceData['productSub'] = navigator.productSub;
        deviceData['vendor'] = navigator.vendor;
        deviceData['vendorSub'] = navigator.vendorSub;
    } catch (e) {
        deviceData['failed'] = true;
    }

    return deviceData;
}

function Alert(props) {
    return <MuiAlert elevation={6} variant={"filled"} {...props} />;
}

const MdApp = () => {
    const pixiApp = useRef(null);
    if (pixiApp.current == null) {
        pixiApp.current = new PIXI.Application({
            width: 400,
            height: 400,
            backgroundColor: 0x000000,
            transparent: true,
            antialias: false,
        });
    }

    const [animState, setAnimState] = useState({});
    const spritesRef = useRef();
    const [shaders, setShaders] = useState([]);
    const soundSamplesRef = useRef([]);
    const pixiDivRef = useRef();

    const [tempo, _setTempo] = useState(120);
    const [compLength, _setCompLength] = useState(16);

    const tempoRef = useRef(tempo);
    const compLengthRef = useRef(compLength);

    const fxRecordingRef = useRef([]);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [snackbarText, setSnackbarText] = useState('');

    const isMobileDevice = useRef();

    const [hideControls, _setHideControls] = useState(false);
    const hideControlsRef = useRef(false);

    const setHideControls = (newHideControls) => {
        hideControlsRef.current = newHideControls;
        _setHideControls(newHideControls);
        console.log(`setting hideControls to ${newHideControls}`)
        handleResize();
    }

    const sfx_lpf = useRef(null);
    if (sfx_lpf.current == null) {
        sfx_lpf.current = new TestFilter(PIXI.default.sound.context.audioContext);
    }

    const sfx_delay = useRef(null);
    if(sfx_delay.current == null) {
        sfx_delay.current = new DelayFilter(PIXI.default.sound.context.audioContext);
    }
    const sfx_reverb= useRef(null);
    if(sfx_reverb.current == null) {
        sfx_reverb.current = new ReverbFilter(PIXI.default.sound.context.audioContext);
    }

    const sfx_distort = useRef(null);
    if(sfx_distort.current == null) {
        sfx_distort.current = new DistortionFilter(PIXI.default.sound.context.audioContext, 0.0);
    }

    const setTempo = (data) => {
        _setTempo(data);
        tempoRef.current = data;
    }

    const setCompLength = (data) => {
        _setCompLength(data);
        compLengthRef.current = data;
        mdMetro.current.restartTransport();

    }

    const [playState, _setPlayState] = useState(false);
    const [recordState, setRecordState] = useState(false);

    const playStateRef = useRef(playState);
    const fxLastPosition = useRef({
        0: sfx_lpf.current.getDefaultPosition(),
        1: sfx_delay.current.getDefaultPosition(),
        2: sfx_reverb.current.getDefaultPosition(),
        3: sfx_distort.current.getDefaultPosition()
    });

    const setPlayState = (data) => {
        _setPlayState(data);
        playStateRef.current = data;

        console.log(`setPlayState(${data})`);
        if (data === true) {
            mdMetro.current.setPlayState(true);
        } else {
            mdMetro.current.setPlayState(false);
        }

    }

    const [compState, _setCompState] = useState([]);
    const [fxSelected, setFxSelected] = useState([false, false, false, false]);

    const compStateRef = useRef(compState);

    const setCompState = (data) => {
        compStateRef.current = data;
        _setCompState(data);
    }

    const mdTransportPosition = useRef(0);
    const recordStateRef = useRef(false);

    const [currentPage, setCurrentPage] = useState('drumpad'); // valid are 'drumpad', 'sequencer', 'mixer', 'effects', 'settings'
    const [currentPack, _setCurrentPack] = useState(0);

    const currentPackRef = useRef(currentPack);

    const setCurrentPack = (data) => {
        _setCurrentPack(data);
        currentPackRef.current = data;
    }

    const [mixerState1, _setMixerState1] = useState(createPackMixerState(12));
    const [mixerState2, _setMixerState2] = useState(createPackMixerState(12));
    const [mixerState3, _setMixerState3] = useState(createPackMixerState(12));

    const mixerState1Ref = useRef(mixerState1);
    const mixerState2Ref = useRef(mixerState2);
    const mixerState3Ref = useRef(mixerState3);

    const setMixerState1 = (data) => {
        _setMixerState1(data);
        mixerState1Ref.current = data;
    }

    const setMixerState2 = (data) => {
        _setMixerState2(data);
        mixerState2Ref.current = data;
    }
    const setMixerState3 = (data) => {
        _setMixerState3(data);
        mixerState3Ref.current = data;
    }


    useEffect(() => {
        setCompState(createInitComp());
        let msc_cb = MdEventEmitter.subscribe('mixerStateChange', data => {
            try {
                let {vSample, aSample} = getSampleByIdx(data.pack, data.idx);
                vSample.alpha = data.newVal * 0.01;
                aSample.sound.volume = Math.pow(data.newVal * 0.01, 2);
            } catch (e) {
                // console.error(e);
            }
        });
        let tpu_cb = MdEventEmitter.subscribe('transportPositionUpdate', data => {
            mdTransportPosition.current = data.position;
            if (fxRecordingRef.current[data.fxIdx]) {
                MdEventEmitter.dispatch('fx-playback-event', {fxState: fxRecordingRef.current[data.fxIdx]});

                for (let i in fxRecordingRef.current[data.fxIdx]) {
                    updateEffectsState(i, fxRecordingRef.current[data.fxIdx][i]);
                }
            }
        });

        let sc_cb = MdEventEmitter.subscribe('submit-composition', data => {
            console.log("submit-composition received");

            let title = 'test submission';
            let created_by = 'anonymous';
            let geolocation_data = {};

            try{
                console.log(`composition title: ${data.title}; created_by: ${data.created_by}`);
                title = data.title;
                created_by = data.created_by;
                console.log(data.geolocation);
                geolocation_data = data.geolocation;
            } catch (e) {
                console.error(e);
            }


            let submission = {
                title: title,
                created_by: created_by,
                geolocation: geolocation_data,
                comp: compStateRef.current,
                compLength: compLengthRef.current,
                tempo: tempoRef.current,
                mixerState: [
                    mixerState1Ref.current,
                    mixerState2Ref.current,
                    mixerState3Ref.current,
                ],
                fxState: fxLastPosition.current,
                packRefPaths: config.pack_ref_paths,
                fxRecording: fxRecordingRef.current,
                version: config.submission_version, // version should come from here, not from the API.
                deviceData: getDeviceData(),
            };
            // axios.post(`http://${config.api_ip}:3100/api/user/composition/submit`, submission)
            axios.post(`/api/user/composition/submit`, submission)
                .then(response => {
                    setSnackbarSeverity("success");
                    setSnackbarText("Successfully submitted!");
                    setSnackbarOpen(true);
                })
                .catch(e => {
                    let errorMsg = 'Error submitting composition.';
                    if (e.hasOwnProperty('response')) {
                        try {
                            errorMsg = e.response.data.response;
                        } catch (e) {
                        }
                    }
                    setSnackbarSeverity("error");
                    setSnackbarText(errorMsg);
                    setSnackbarOpen(true);
                });
        })
        MdEventEmitter.dispatch("setFxLastPosition", fxLastPosition.current);

        return () => {
            MdEventEmitter.unsubscribe('mixerStateChange', msc_cb);
            MdEventEmitter.unsubscribe('transportPositionUpdate', tpu_cb);
            MdEventEmitter.unsubscribe('submit-composition', sc_cb);
        }
    }, []);

    useEffect(() => {
        mdMetro.current.updateCompState(compState);
    }, [compState]);

    useEffect(() => {
        recordStateRef.current = recordState;
        MdEventEmitter.dispatch("recordStateChange", {recordState: recordStateRef.current});
        if (currentPage === 'effects' && recordState) fxRecordingRef.current = [];
        if (recordState) {
            if (!playState) {setPlayState(true);}
        }
    }, [recordState]);

    const mdMetro = useRef(null);

    if (mdMetro.current == null) {
        mdMetro.current = new MdMetronome(
            PIXI.default.sound.context._ctx,
            compState,
        )
    }

    useEffect(() => {
        if (currentPage === 'effects' && recordState) {
            mdMetro.current.allowLoop = false;
        } else {
            mdMetro.current.allowLoop = true;
        }
    }, [currentPage, recordState]);

    const stylesClasses = MuiLayoutStyles()
    const samplePlaybackReferences = useRef({});

    const clearCurrent = () => {
        if (currentPage === "effects") {
            //TODO this is getting called upon load for some reason. Don't automatically call?
            clearEffectsState();
        } else if (currentPage === 'mixer') {
            selectSetMixerState()(createPackMixerState(12));
        } else {
            clearCurrentPackComp();
        }
    }

    const clearCurrentPackComp = () => {
        let newCompState = {...compState};
        newCompState[currentPack] = makeEmptyPack();
        setCompState(newCompState);
    }

    const selectMixerState = () => {
        if (currentPack === 0) return mixerState1;
        if (currentPack === 1) return mixerState2;
        if (currentPack === 2) return mixerState3;
        throw new Error("error");
    }

    const selectSetMixerState = () => {
        if (currentPack === 0) return setMixerState1;
        if (currentPack === 1) return setMixerState2;
        if (currentPack === 2) return setMixerState3;
        throw new Error("error");
    }

    const audioSamplesNamesRef = useRef({});
    const packRefsRef = useRef([]);

    const loadAudioSamples = (audioRefPath) => {
        const SoundLoader = new PIXI.Loader();
        fetch(audioRefPath)
            .then(res => res.json())
            .then(res => {
                let tLen = Object.keys(res.samples).length;
                for (let idx of Object.keys(res.samples)) {
                    if (res.samples[idx].includes('.json')) {
                        delete res.samples[idx];
                    }
                }
                if (tLen > 12) {
                    for (let removeItem of Object.keys(res.samples).slice(12)) {
                        delete res.samples[removeItem];
                    }
                }
                let newAudioSampleNames = {...audioSamplesNamesRef.current};
                newAudioSampleNames[res.name] = res.samples;
                audioSamplesNamesRef.current = newAudioSampleNames;
                return res;
            })
            .then(res => {
                let samplesManifest = res.samples;
                for (const sampleName in samplesManifest) {
                    let ara = getAudioRefsAddress(samplesManifest[sampleName]);
                    SoundLoader.add(sampleName, getAudioRefsAddress(samplesManifest[sampleName]));
                }
                SoundLoader.load((loader, resources) => {
                    for (const sample in resources) {
                        try {
                            resources[sample].sound.volume = Math.pow(config.ui_settings.mixer.default_volume * 0.01, 2);
                            soundSamplesRef.current.push(resources[sample]);
                        } catch (e) {
                            console.error(e);
                        }
                    }
                });
                return res;
            })
            .then(res => {
                console.log("DONE SETTING UP AUDIO SAMPLES");
            })
            .catch(console.error);
    }

    const loadAllAnimationsToSprites = (animations) => {
        cleanupStage();
        let t_sprites = {};
        for (const anim in animations) {
            animations[anim].sort((a, b) => {
                if (a.textureCacheIds[0] > b.textureCacheIds[0]) {
                    return 1;
                } else {
                    return -1;
                }
            });

            animations[anim] = animations[anim].filter( x => {
                return x !== undefined;
            });

            if (!packRefsRef.current.includes(anim)) packRefsRef.current.push(anim);
            let t_animSprite = new PIXI.AnimatedSprite(animations[anim]);
            t_animSprite.loop = false;
            t_animSprite.blendMode = PIXI.BLEND_MODES.ADD;
            t_animSprite.animationSpeed = 0.4;
            t_animSprite.gotoAndStop(t_animSprite.textures.length - 1);

            //TODO: check possible weak point. pixiDivRef client size for setting sprite size here.
            t_animSprite.width = pixiDivRef.current.clientWidth;
            t_animSprite.height = pixiDivRef.current.clientHeight;
            t_animSprite.alpha = config.ui_settings.mixer.default_volume * 0.01;
            pixiApp.current.stage.addChild(t_animSprite);
            t_sprites[anim] = t_animSprite;
        }
        spritesRef.current = t_sprites;
    }

    const updateSpriteSizes = (newWidth, newHeight) => {
        for (let sprite in spritesRef.current) {
            spritesRef.current[sprite].width = newWidth;
            spritesRef.current[sprite].height = newHeight;
        }
    }

    const setupVideoSprites = (newSpriteSheet, packNumber) => {
        let Animations = animState;
        let videoLoader = new PIXI.Loader();
        videoLoader.add(newSpriteSheet)
            .load(() => {
                for (const f of newSpriteSheet) {
                    let t_sheet = videoLoader.resources[f].spritesheet;
                    let t_anims = t_sheet.animations;
                    for (const anim in t_anims) {
                        if (Animations.hasOwnProperty(anim)) {
                            Animations[anim] = [...Animations[anim], ...t_anims[anim]];
                        } else {
                            Animations[anim] = t_anims[anim];
                        }
                    }
                }
                setAnimState(Animations);
                loadAllAnimationsToSprites(Animations, packNumber);
            });
        console.log("DONE SETTING UP VIDEO SPRITES");
    }

    const cleanupStage = () => {
        for (let i = pixiApp.current.stage.children.length - 1; i >= 0; i--) {
            pixiApp.current.stage.removeChild(pixiApp.current.stage.children[i]);
        }

        setPlayState(false);
    }

    const triggerSample = (sample_name) => {
        try {
            if (spritesRef.current.hasOwnProperty(sample_name)) {
                spritesRef.current[sample_name].gotoAndPlay(0);
            } else {
                console.log(`sample (${sample_name}) hasn't been loaded.`);
            }
        } catch (e) {
            console.error(e);
        }
    }

    const triggerSampleByIdx = (sampleIdx) => {
        let cPack = Math.floor(sampleIdx / 12);
        let sIdx = sampleIdx % 12;
        try {
            triggerSample(samplePlaybackReferences.current[cPack][sIdx].video);
            let tAudioSample = soundSamplesRef.current.filter(a => {
                return a.url.includes(samplePlaybackReferences.current[cPack][sIdx].audio);
            });
            if (tAudioSample.length >= 1) {
                tAudioSample[0].sound.play();
            } else {
                console.error("not playing sound for some reason");
            }
        } catch (e) {
            console.error(e);
            console.log(`cPack: ${cPack}, sIdx: ${sIdx}`);
        }
    }

    const getSampleByIdx = (packNumber, sampleIdx) => {
        try {
            let vSample = spritesRef.current[samplePlaybackReferences.current[packNumber][sampleIdx].video];
            let tAudioSample = soundSamplesRef.current.filter(a => {
                return a.url.includes(samplePlaybackReferences.current[packNumber][sampleIdx].audio);
            });
            let aSample = null;
            if (tAudioSample.length >= 1) {
                aSample = tAudioSample[0];
            }
            return {vSample, aSample};
        } catch (e) {
            // console.error(e);
        }
        return null;
    }

    useEffect(() => {
        if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
            isMobileDevice.current = true;
            let orientation = (window.screen.orientation || {}).type || window.screen.mozOrientation || window.screen.msOrientation;
            if (orientation === 'portrait-primary') {
                setHideControls(false);
            } else if (orientation === 'landscape-primary') {
                setHideControls(true);
            }

        } else {
            isMobileDevice.current = false;
        }

        let oc = window.addEventListener("orientationchange", function(event) {
            if (isMobileDevice.current) {
                if(/Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                    let orientation = (window.screen.orientation || {}).type || window.screen.mozOrientation || window.screen.msOrientation;

                    if (orientation.includes('landscape')) {
                        setHideControls(true);
                    } else {
                        setHideControls(false);
                    }
                } else {
                    // for iOS (iphone, ipod, ipad)
                    let orientation = window.orientation;
                    console.log(orientation);

                    if(orientation === 0) {
                        setHideControls(false);
                    } else if (orientation === 90 || orientation === -90) {
                        setHideControls(true);
                    }
                }

            }
        });
        mdMetro.current.timerWorker.onmessage = function (e) {
            if (e.data === 'tick') {
                mdMetro.current.scheduler();
            }
        }
        mdMetro.current.timerWorker.postMessage({"interval": mdMetro.current.lookahead});
        return () => {window.removeEventListener('orientationchange', oc);};
    }, []);

    useEffect(() => {
        mdMetro.current.tempo = tempo;
        sfx_delay.current.tempo = tempo;
    }, [tempo]);

    const handleResize = () => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        pixiApp.current.resizeTo = pixiDivRef.current;
        updateSpriteSizes(pixiDivRef.current.clientWidth, pixiDivRef.current.clientHeight);
    }

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []); //TODO: should this only fire once?

    useEffect(() => {
        document.getElementById('ref-pixi-div').appendChild(pixiApp.current.view);
        let displacementSprite = PIXI.Sprite.from(DisplacementSprite);
        displacementSprite.texture.baseTexture.wrapMode = PIXI.WRAP_MODES.REPEAT;
        setShaders([...shaders,
            //These are VFX 1
            {name: "rgbsplit", filter: new RGBSplitFilter([0,0], [0,0], [0,0])}, //default to NO split.
            {name: "levels", filter: new AdjustmentFilter({saturation: 1, brightness: 1, gamma: 1})}, //default to no adjustment.

            //These are VFX 2
            {name: "kawaseblur", filter: new KawaseBlurFilter(0, 1, false)},

            //These are VFX 3
            {name: "zoomblur", filter: new ZoomBlurFilter({strength: 0, center: [0,0], innerRadius: 100, radius: -1})},
            {name: "displacement", filter: new PIXI.filters.DisplacementFilter(displacementSprite, 0)},

            //These are VFX 4
            {name: "pixelate", filter: new PixelateWithMixFilter([1, 1], 1.0)},
        ]);
        pixiApp.current.stage.interactiveChildren = false;
    }, []);

    useEffect(() => {
        let dpt_cb = MdEventEmitter.subscribe('drumpad_toggle', (data) => {
            if (recordStateRef.current) {
                let cPack = Math.floor(data.triggerId / 12);
                let sIdx = data.triggerId % 12;
                compStateRef.current[cPack][sIdx][mdTransportPosition.current] = true;
                setCompState(compStateRef.current);
                if (!playStateRef.current) {
                    triggerSampleByIdx(data.triggerId);
                }
            } else {
                triggerSampleByIdx(data.triggerId);
            }
        });

        let st_cb = MdEventEmitter.subscribe('sample_trigger', (data) => {
            triggerSampleByIdx(data.triggerId);
        });

        let fxe_cb = MdEventEmitter.subscribe('fxEvent', (data) => {
            if (data.indices[0]) updateEffectsState(0, data);
            if (data.indices[1]) updateEffectsState(1, data);
            if (data.indices[2]) updateEffectsState(2, data);
            if (data.indices[3]) updateEffectsState(3, data);
        });

        let fxre_cb = MdEventEmitter.subscribe('fxRecordEvent', (data) => {
            fxRecordingRef.current[data.position-1] = data.fxState;
        });

        return () => {
            MdEventEmitter.unsubscribe('drumpad_toggle', dpt_cb);
            MdEventEmitter.unsubscribe('sample_trigger', st_cb);
            MdEventEmitter.unsubscribe('fxEvent', fxe_cb);
            MdEventEmitter.unsubscribe('fxRecordEvent', fxre_cb);
        }
    }, []);

    const updateEffectsState = (effectIdx, data) => {
        if (data.x < 0 || data.x > 1 || data.y < 0 || data.y > 1) {
            clearEffect(effectIdx);
            return;
        }

        if (effectIdx === 0 || effectIdx === '0') {
            fxLastPosition.current[0] = {x: data.x, y: data.y};
            pixiApp.current.stage.filters[0].red = [data.y * -10, data.x * 15];
            pixiApp.current.stage.filters[0].blue = [data.x * 22, 0];
            pixiApp.current.stage.filters[1].saturation = data.x * ((1.0 - data.y) * 2.0);
            pixiApp.current.stage.filters[1].contrast =((1 - data.x) * 0.25) + ((1 - data.y) * 0.25) + 1.0;
            sfx_lpf.current.x = data.x;
            sfx_lpf.current.y = data.y;
        }

        if (effectIdx === 1 || effectIdx === '1') {
            fxLastPosition.current[1] = {x: data.x, y: data.y};
            sfx_delay.current.x = data.x;
            sfx_delay.current.y = data.y;
            pixiApp.current.stage.filters[2].blur = (data.x) * 20.0;
            pixiApp.current.stage.filters[2].pixelSize = [1.0, (1.0 - data.y) * 2];
        }

        if (effectIdx === 2 || effectIdx === '2') {
            fxLastPosition.current[2] = {x: data.x, y: data.y};
            sfx_reverb.current.x = data.x;
            sfx_reverb.current.y = 1 - data.y;
            pixiApp.current.stage.filters[3].center = [data.raw_x, data.raw_y];
            pixiApp.current.stage.filters[3].strength = (data.y * 0.4) + 0.1;
            pixiApp.current.stage.filters[4].scale.x = data.x * 200;
            pixiApp.current.stage.filters[4].scale.y = data.y * 50;
        }

        if (effectIdx === 3 || effectIdx === '3') {
            fxLastPosition.current[3] = {x: data.x, y: data.y};
            sfx_distort.current.x = data.x;
            sfx_distort.current.y = data.y;
            pixiApp.current.stage.filters[5].size = [Math.ceil(data.x * 20.0) + 1, Math.ceil(data.y * 8.0) + 1];
        }
    }

    const clearEffect = (effectIdx) => {
        if (typeof effectIdx === "string") {effectIdx = parseInt(effectIdx);}

        switch (effectIdx) {
            case 0:
                sfx_lpf.current.clear();
                pixiApp.current.stage.filters[0].red = [0, 0];
                pixiApp.current.stage.filters[0].blue = [0, 0];
                pixiApp.current.stage.filters[1].saturation = 1;
                pixiApp.current.stage.filters[1].contrast = 1;
                break;
            case 1:
                sfx_delay.current.clear();
                pixiApp.current.stage.filters[2].blur = 0.0;
                pixiApp.current.stage.filters[2].pixelSize = [1.0, 1.0];
                break;
            case 2:
                sfx_reverb.current.clear();
                pixiApp.current.stage.filters[3].strength = 0;
                pixiApp.current.stage.filters[3].center = [0,0];
                pixiApp.current.stage.filters[4].scale.x = 0;
                pixiApp.current.stage.filters[4].scale.y = 0;
                break;
            case 3:
                sfx_distort.current.clear();
                pixiApp.current.stage.filters[5].size = [1, 1];
                break;
            default:
                break;
        }
    }

    const clearEffectsState = () => {
        for (let i=0; i<4; i ++) {
            fxLastPosition.current[i] = {x: 0.0, y: -1.0};
            clearEffect(i);
        }
        fxRecordingRef.current = [];
        MdEventEmitter.dispatch("setFxLastPosition", fxLastPosition.current);
    }

    useEffect(() => {
        pixiApp.current.stage.filters = shaders.map(s => s.filter);
    }, [shaders]);

    useEffect(() => {
        MdEventEmitter.dispatch('compLengthAdjust', {newLength: compLength});
    }, [compLength]);

    useEffect(() => {
        for (let packrefpath in config.pack_ref_paths) {
            fetch(packRefPrefix + config.pack_ref_paths[packrefpath])
                .then(res => {
                    return res.json();
                })
                .then(res => {
                    samplePlaybackReferences.current[packrefpath] = res.indices;
                    return res;
                })
                .then(res => {
                    let newRes = res.video.map(getSpritesheetAddress);
                    setupVideoSprites(newRes);
                    return res;
                })
                .then(res => {
                    loadAudioSamples(getAudioRefsAddress(res.audio));
                })
                .catch(console.error);
        }

        //MARK : set up SFX filters.
        PIXI.default.sound.filtersAll = [
            new PIXI.default.sound.filters.Filter(sfx_lpf.current.source, sfx_lpf.current.destination),
            new PIXI.default.sound.filters.Filter(sfx_delay.current.source, sfx_delay.current.destination),
            new PIXI.default.sound.filters.Filter(sfx_reverb.current.source, sfx_reverb.current.destination),
            new PIXI.default.sound.filters.Filter(sfx_distort.current.source, sfx_distort.current.destination),
        ];

        return cleanupStage;
    }, []);

    useEffect(() => {
        return () => {
            setPlayState(false);
            setRecordState(false);
        }
    }, []);

    const getCurrentPage = () => {
        if (currentPage === 'drumpad') {
            return <MdDrumpadUi currentPack={currentPack} role={"Drumpad / Performance Interface"}/>
        } else if (currentPage === 'sequencer') {
            return <MdSequencerUi currentPack={currentPack} compState={compState} setCompState={setCompState}
                                  compLength={compLength}
                                  role={"Sequencer Interface"}
            />
        } else if (currentPage === 'mixer') {
            return <MdMixerUi currentPack={currentPack} mixerState={selectMixerState()}
                              setMixerState={selectSetMixerState()}
                              role={"Mixer Interface"}
            />
        } else if (currentPage === 'effects') {
            return <MdEffectsUi fxSelected={fxSelected} fxLastPosition={fxLastPosition} role={"Effects Interface"}/>
        } else if (currentPage === 'settings') {
            return <MdSettingsUi
                tempo={tempo}
                setTempo={setTempo}
                compLength={compLength}
                setCompLength={setCompLength}
                role={"Settings Interface"}
            />
        } else {
            return (
                <div>
                    ERROR. Invalid page selected.
                    How did you do that?!
                </div>
            )
        }
    }

    const getSelectorUi = () => {
        if (currentPage === 'effects') {
            return <MdEffectsSelector fxSelected={fxSelected} setFxSelected={setFxSelected} aria-label={"Effects Selector"}/>
        } else {
            return <MdPackSelector currentPack={currentPack} setCurrentPack={setCurrentPack} aria-label={"Sample Pack Selector"}/>
        }
    }

    const renderAllControls = () => {
        return (
            <>
                <Container maxWidth={'sm'} className={stylesClasses.containerRoot}>
                    <Box className={stylesClasses.webglDisplay} height={"45%"}>
                        <Box className={stylesClasses.insetDisplay}>
                            <div ref={pixiDivRef} id={"ref-pixi-div"} aria-label={"Video Playback"}></div>
                        </Box>
                    </Box>
                    <Box className={stylesClasses.controlsDiv}>
                        <Box style={{"backgroundColor": "#000000"}} className={stylesClasses.midControls}>
                            <MdPlayControls
                                playState={playState}
                                setPlayState={setPlayState}
                                recordState={recordState}
                                setRecordState={setRecordState}
                                tempo={tempo}
                                setTempo={setTempo}
                                compLength={compLength}
                                setCompLength={setCompLength}
                                clearCurrentPack={clearCurrent}
                                role={"controls"}
                            />
                            {getSelectorUi()}
                        </Box>
                        <Box style={{"backgroundColor": "#000000"}} className={stylesClasses.bottomControls} role={"controls"}>
                            {getCurrentPage()}
                        </Box>
                    </Box>
                    <Snackbar autoHideDuration={3000} open={snackbarOpen} onClose={(e, r) => {
                        if (r === 'clickaway') return;
                        setSnackbarOpen(false)}}>
                        <Alert severity={snackbarSeverity} onClose={(e, r) => {
                            if (r === 'clickaway') return;
                            setSnackbarOpen(false);
                        }}
                               role={"alert"}
                        >{snackbarText}</Alert>
                    </Snackbar>
                </Container>
                <MdBottomNav currentPage={currentPage} setCurrentPage={setCurrentPage} role={"navigation"}/>
            </>
        )
    }

    const renderVideoPreview = () => {
        return (
            <>
               <Container maxWidth={"lg"} className={stylesClasses.containerFullscreen}>
                   <Box className={stylesClasses.webglDisplay} height={"100%"}>
                       <Box className={stylesClasses.insetDisplay}>
                           <div ref={pixiDivRef}
                                id={"ref-pixi-div"}
                                aria-label={"Video Playback"}
                           ></div>
                       </Box>
                   </Box>
               </Container>
            </>
        )
    }

    return (
        <>
        {(hideControls) ? renderVideoPreview() : renderAllControls()}
        </>
    )
}

export default MdApp;
