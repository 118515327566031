import React from 'react';
import Box from '@material-ui/core/Box';

const MdEffectsSelector = (props) => {
    const toggleFxSelected = (fxIdx) => {
        let newFxSelected = [...props.fxSelected];
        newFxSelected[fxIdx] = !newFxSelected[fxIdx];
        props.setFxSelected(newFxSelected);
    }

    return (
        <Box py={0} height={"49%"} style={{'display': 'flex', "marginTop": "1%"}}>
            <button onClick={() => {toggleFxSelected(0)}}
                    aria-label={"Toggle to enable/disable effect 1"}
                    className={(props.fxSelected[0]) ? "effects-select-button effects-select-1" : "effects-select-button effects-select-inactive"}>
                <span className={(props.fxSelected[0]) ? 'dot dot-active' : "dot dot-inactive"}/>
            </button>
            <button onClick={() => {toggleFxSelected(1)}}
                    aria-label={"Toggle to enable/disable effect 2"}
                    className={(props.fxSelected[1]) ? "effects-select-button effects-select-2" : "effects-select-button effects-select-inactive"}>
                <span className={(props.fxSelected[1]) ? 'dot dot-active' : "dot dot-inactive"}/>
                <span className={(props.fxSelected[1]) ? 'dot dot-active' : "dot dot-inactive"}/>
            </button>
            <button onClick={() => {toggleFxSelected(2)}}
                    aria-label={"Toggle to enable/disable effect 3"}
                    className={(props.fxSelected[2]) ? "effects-select-button effects-select-3" : "effects-select-button effects-select-inactive"}>
                <span className={(props.fxSelected[2]) ? 'dot dot-active' : "dot dot-inactive"}/>
                <span className={(props.fxSelected[2]) ? 'dot dot-active' : "dot dot-inactive"}/>
                <span className={(props.fxSelected[2]) ? 'dot dot-active' : "dot dot-inactive"}/>
            </button>
            <button onClick={() => {toggleFxSelected(3)}}
                    aria-label={"Toggle to enable/disable effect 4"}
                    className={(props.fxSelected[3]) ? "effects-select-button effects-select-4" : "effects-select-button effects-select-inactive"}>
                <span className={(props.fxSelected[3]) ? 'dot dot-active' : "dot dot-inactive"}/>
                <span className={(props.fxSelected[3]) ? 'dot dot-active' : "dot dot-inactive"}/>
                <span className={(props.fxSelected[3]) ? 'dot dot-active' : "dot dot-inactive"}/>
                <span className={(props.fxSelected[3]) ? 'dot dot-active' : "dot dot-inactive"}/>
            </button>
        </Box>
    )
}

export default MdEffectsSelector;
