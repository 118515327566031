import React, {useRef} from 'react';
import {Container} from '@material-ui/core';
import './infoStyles.css';
import bannerImage from './images/MD_Cincy_header.jpg';
import MdFooter from "./MdFooter";
import blink_logo from './images/Blink_Artworks Logo_v1.jpg';
import artworks_photo from './images/ArtWorks group pic_3.jpg';
import md_example from './images/MD - sample example_4.jpg';

import SampleDetails_OH from "./SampleDetails/SampleDetails_OH";

const MdOh = () => {
    const mapRef = useRef(null);

    return (
        <Container maxWidth={"md"} className={"md-info-pages-container"} role={"main"}>
            <div>
                <img src={bannerImage}
                     alt={"Graphic which has Cincinnati, Ohio on top of it. The graphic is an abstract gradient of teal, purple, and green. It includes rectangles, small specles of noise."}
                     className={'md-image-el-hc'}
                     role={"banner"}/>
            </div>
            <div className={"md-info md-info-just"}>
                <div className={"md-info-div"}>
                    Massively Distributed: Cincinnati in partnership with ArtWorks, presented by BLINK 2022 from
                    October 13-16. The web-app instrument is live from Oct 1 - Nov. 15. MD will be installed and active
                    for the duration of the festival with daily ‘meta-composition’ updates which include all public
                    submissions with the web-app.
                </div>
                <div className={"md-banner-image-div"}>
                    <img src={blink_logo} alt={"BLINK logo on the left in white, ArtWorks logo on the right in pink."}/>
                </div>
                <div className={"md-info-div"}>
                    BLINK is an annual free and public light art event embedded in downtown Cincinnati, OH. As part of
                    the festival, MD is installed off of Vine St and W 3rd St <a target={'_blank'}
                                                                                 rel={'noopener noreferrer'}
                                                                                 href={"https://www.google.com/maps/place/Cincinnati,+OH/@39.0980384,-84.512485,52a,35y,7.19h,67.6t/data=!3m1!1e3!4m5!3m4!1s0x884051b1de3821f9:0x69fb7e8be4c09317!8m2!3d39.1031182!4d-84.5120196"}>(link
                    to map)</a>. All of the submitted creations (made by you!) from this <a
                    href={'app'}>web-app</a> are compiled into larger audio visual artworks. These will
                    be projected at a large scale (the whole side of the building) and the sound amplified through a
                    sound system. That means that <i>your creations</i> are what drive this artwork. Get started now
                    making
                    your own sound and light creation… title it, add your DJ name and submit for inclusion. Then come to
                    the free festival to see all the artworks - including yours as a part of Massively Distributed.
                </div>

                <div className={"md-info-div"}>
                    Be sure to follow <a target={'_blank'} rel={'noopener noreferrer'}
                                         href={"https://www.instagram.com/blinkcincinnati/"}>@BlinkFestival</a> and
                    <a href={"https://www.instagram.com/masary_studios/"}> @Masary_Studios</a> for updates on when your
                    submission(s) will be shown.
                </div>

                <div className={"md-info-div"}>
                    View initial meta-compositions submitted by ArtWorks from Cincinnati, OH at the bottom of this page.
                </div>

                <div className={"md-info-div"}>
                    Click <a target={'_blank'} rel={'noopener noreferrer'} href={"https://blinkcincinnati.com/"}>HERE
                    for more information on BLINK.</a>
                </div>

                <div className={"md-info-div"}>
                    Click <a target={'_blank'} rel={'noopener noreferrer'} href={"https://www.artworkscincinnati.org/"}>HERE
                    to learn more about ArtWorks</a>
                </div>
            </div>

            <h1>About the Samples</h1>
            <div className={"md-info-div md-info-just"}>
                All of the samples used in MD were recorded in and around Cincinnati. Through a partnership with
                ArtWorks, a group of four youth apprentices and a local artist captured video, photo, and audio
                throughout the city. Different areas of the city were sought out with direction from MASARY, giving the
                recording process structure while guiding the local team to be intuitive and define their own
                definitions of place.
            </div>

            <div className={"md-banner-image-div"}>
                <img src={artworks_photo}
                     alt={"Three side-by-side photos of ArtWorks youth and artist Joe Walsh out in Cincinnati with video and photo equipment while collecting sound and visual samples for ‘Massively Distributed: Cincinnati’."}
                     className={'md-image-el'}/>
            </div>

            <div className={"md-info-div md-info-just"}>
                We would like to thank the ArtWorks team involved in exploring, recording, and presenting us with the
                media from Cincinnati, OH:
                Sylvie Tiro, Eva Robles, Adia Eagle, Nata Thiam, Joe Walsh, Devin Grimm, & Liz Miller. Thank you!

            </div>

            <div className={"md-info-div md-info-just"}>
                Hundreds of recordings were sent to MASARY in order to construct 36 total audio visual samples across 3
                packs within MD. The audio and visual recordings were edited in various creative softwares to prepare
                them for use. See all samples below.
            </div>

            <div className={'md-banner-image-div'}>
                <img src={md_example}
                     alt={"The progression of editing a visual sample for ‘Massively Distributed: Cincinnati’, starting with the original image of a section of the Roebling Bridge, to the cropped and then colorized portion used for the web-app."}
                     className={'md-image-el'}/>
            </div>

            <h2>Sample Notes and Artistic Treatment</h2>
            <SampleDetails_OH/>

            <h2>Sample Sources</h2>

            <div className={"md-info-div md-info-just"}>
                <div className={"md-info-bump-extra"}>
                    The map below shows where the samples were recorded by the ArtWorks team in Cincinnati and where to
                    find them in the web-app.
                </div>

                <div className={"md-info-div"}>
                    <iframe
                        title={"Massively Distributed at BLINK: Cincinnati, 2022: Sample Sources Map"}
                        className={"map-iframe"}
                        ref={mapRef}
                        src="https://www.google.com/maps/d/u/0/embed?mid=1zm_wpVLyZQ9ryxDVYkKoH1IV-3j4wzk&ehbc=2E312F"
                        width="640" height="480"></iframe>
                </div>
                <div className={'md-info-bump-extra'}>
                    As MD is activated in other places new samples will be recorded, new communities will create with
                    the web-app, and new site-specific compositions will be meta-composed into public installations.
                </div>

                <h2>Meta-Compositions</h2>
                <div className={"md-info-div md-info-just"}>
                    <div className={"md-trailer"}>
                    <iframe
                        title={"vimeo-player"}
                        src="https://player.vimeo.com/video/754065272?h=74ffe4a217&title=0&byline=0&portrait=0"
                            width="640" height="480" frameborder="0" allow="autoplay; fullscreen; picture-in-picture"
                            allowfullscreen></iframe>
                    </div>
                </div>

            </div>
            <MdFooter/>
        </Container>
    )
}

export default MdOh;
