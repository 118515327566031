const defaultVert = `
attribute vec2 aVertexPosition;\n
attribute vec2 aTextureCoord;\n
uniform mat3 projectionMatrix;\n
varying vec2 vTextureCoord;\n
void main(void)\n
{\n
    gl_Position = vec4((projectionMatrix * vec3(aVertexPosition, 1.0)).xy, 0.0, 1.0);\n
    vTextureCoord = aTextureCoord;\n
}`;


export { defaultVert };