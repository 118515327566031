import React, {useRef} from 'react';
import { Container } from '@material-ui/core';
import './infoStyles.css';
import bannerImage from './images/etc/MD_AZ_header.jpg';
import cc_logo from './images/Canal-Convergence-Logo_1.jpg';
import MdFooter from "./MdFooter";

import im1 from './images/MD_MASARY_ByAramBohosian.jpg';
import SampleDetails from './SampleDetails/SampleDetails';

const MdAz = () => {
    const mapRef = useRef(null);

    return (
        <Container maxWidth={"md"} className={"md-info-pages-container"}>
            <div>
                <img src={bannerImage} alt={"Massively Distributed AZ"} className={'md-image-el'} />
            </div>
            <div className={"md-info md-info-just"}>
                <div className={"md-info-div"}>
                    Massively Distributed debuted in Scottsdale, AZ, presented by <a target={'_blank'} rel={'noopener noreferrer'} href={"https://canalconvergence.com/public-art/"}>Canal Convergence</a> at their 2020 Fall Experience in and around the downtown city area.
                </div>

                <div className={"md-banner-image-div"}>
                    <img src={cc_logo} alt={"Canal Convergence 2020"}/>
                </div>
            </div>
            <div className={"md-banner-image-div"}>
                <img src={im1} alt={""}/>
            </div>
            <div className={"md-info-div md-info-just"}>
                Produced by Scottsdale Public Art, Canal Convergence is an annual arts event embedded in Oldtown Scottsdale along the Canal. MD was installed at three different locations each with facade video projection and a large sound system. All of the submitted compositions by the public were aggregated into meta-compositions which were then activated at each site simultaneously; allowing the artwork to be made of the place, by the place, and be presented publicly, in the place.
            </div>
            {/*<div className={"md-info-div md-info-just"}>*/}
            {/*    MASARY is driving across the country during the pandemic from Boston to Scottsdale in order to install the public art for the showing.*/}
            {/*</div>*/}
            <div className={"md-info-div md-info-just"}>
                View the meta-compositions from Scottsdale, AZ at the bottom of this page.
            </div>
            <div className={"md-info-div md-info-just" }>
            <a target={'_blank'} rel={'noopener noreferrer'} href={"https://canalconvergence.com/public-art/"}>Click HERE</a> for more information on Canal Convergence.
            </div>

            <div className={"md-trailer"}>
                <iframe title="vimeo-player" src="https://player.vimeo.com/video/481806240" width="640" height="480"
                        frameBorder="0" allowFullScreen></iframe>
            </div>

            <h1>About the Samples</h1>
            <div className={"md-info-div md-info-just"}>
                All of the samples used in MD were recorded in and around Scottsdale. There was a talented team from Scottsdale Public Art that went out over the course of a month to record everything by video, photo, and audio. Different areas of the city were sought out with direction from MASARY, giving the recording process directed structure while allowing the local team to be intuitive and define their own definitions of place.
            </div>
            <div className={"md-info-div"}>
                We would like to thank our media capture partners in Scottsdale: <strong>ZMB</strong>, <strong>Brian H. Passey</strong>, <strong>viirginiaa</strong>, <strong>n_e_w</strong> for exploring, recording, and presenting us with media representative of the place that is Scottsdale, Arizona.
            </div>
            <div className={"md-info-div md-info-just"}>
                Hundreds of recordings were sent to MASARY in order to construct 36 total audio visual samples across 3 packs within MD. The audio and visual recordings were edited in various creative softwares to prepare them for use.
            {/*</div>*/}
            {/*<div className={"md-info-div"}>*/}
                For example - the LOVE sculpture was recorded multiple times, both with a microphone and with a camera. Editing decisions such as cropping and adding a high pass filter with the audio allowed MASARY to create a kick drum. Images of the sculpture were turned black & white, contrasted, and treated with a ripple effect. Both of these edited pieces of media were joined together to create one sample.
            </div>
            <h2>Sample Notes and Artistic Treatment</h2>
            <SampleDetails/>
            <h2>Sample Sources</h2>

            <div className={"md-info-div md-info-just"}>
                <div className={"md-info-bump-extra"}>
                The map below shows where the samples were recorded by the Scottsdale Public Art media team and where to find them in the web app. As MD is activated in other places, new samples will be recorded, new communities will create with the web app, new site specific compositions will be meta-composed.
                </div>
                <div className={"md-info-div"}>
                    <iframe
                        title={"massively distributed sample sources map"}
                        className={"map-iframe"}
                        ref={mapRef}
                        src="https://www.google.com/maps/d/u/0/embed?mid=1NPbSWKTW6nUXntJHEL5ahEaXN84V2dk_"
                        width="640"
                        height="480"
                    />
                </div>
            </div>

            <h2>Meta-Compositions</h2>
            <div className={"md-info-div md-info-just"}>
                <div className={"md-trailer"}>
                    <iframe title="vimeo-player" src="https://player.vimeo.com/video/482609844" width="640" height="480"
                            frameBorder="0" allowFullScreen></iframe>
                </div>
                <div className={"md-trailer"}>
                    <iframe title="vimeo-player" src="https://player.vimeo.com/video/482656399" width="640" height="480"
                            frameBorder="0" allowFullScreen></iframe>
                </div>
                <div className={"md-trailer"}>
                    <iframe title="vimeo-player" src="https://player.vimeo.com/video/482700764" width="640" height="480"
                            frameBorder="0" allowFullScreen></iframe>
                </div>
                <div className={"md-trailer"}>
                    <iframe title="vimeo-player" src="https://player.vimeo.com/video/485961507" width="640" height="480"
                            frameBorder="0" allowFullScreen></iframe>
                </div>
                <div className={"md-trailer"}>
                    <iframe title="vimeo-player" src="https://player.vimeo.com/video/485960719" width="640" height="480"
                            frameBorder="0" allowFullScreen></iframe>
                </div>
            </div>

            <MdFooter />
        </Container>
    )
}

export default MdAz;
