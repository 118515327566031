const FxColors = {
    0: {
        r: 193,
        g: 49,
        b: 249,
        str: "rgb(193, 49, 249)"
    },
    1: {
        r: 72,
        g: 49,
        b: 255,
        str: "rgb(72, 49, 255)"
    },
    2: {
        r: 98,
        g: 49,
        b: 249,
        str: "rgb(98, 49, 249)"
    },
    3: {
        r: 49,
        g: 223,
        b: 249,
        str: "rgb(49, 223, 249)"
    }
}

export default FxColors;
