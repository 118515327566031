import { makeStyles } from "@material-ui/core/styles";

const MuiLayoutStyles = makeStyles({
    containerRoot: {
        backgroundColor: "#000000",
        overflow: "hidden",
        // height: "calc(100vh - 66px)",
        height: "calc((var(--vh, 1vh) * 100) - 66px)",
        padding: "8px"
    },
    containerFullscreen: {
        backgroundColor: "#000000",
        overflow: "hidden",
        height: "100%",
        padding: "4px",
        position: "absolute",
        top: 0,
        left: 0,
    },
    webglDisplay: {
        backgroundColor: "#999999",
        overflow: "hidden",
    },
    insetDisplay: {
        backgroundColor: "#000000",
        width: "100%",
        height: "100%"
    },
    controlsDiv: {
        height: "55%"
    },
    midControls: {
        height: "61.5px",
        minHeight: "61.5px",
    },
    bottomControls: {
        height: "calc(100% - 61.5px)"
    }
});

export default MuiLayoutStyles;
