import React from 'react';
import masary_logo from './images/Masary_Wordmark_Horizontal_White.png';

const MdFooter = () => {
    return (
        <div className={'md-banner-image-div footer-img'}>
            <a target={'_blank'} rel={'noopener noreferrer'} href={"http://masarystudios.com/"}><img src={masary_logo} alt={"link to masary studios"} /></a>
        </div>
    )
}

export default MdFooter;
