import axios from 'axios';
import config from "../Configs/config";

let reverbFilterUri;

if(process.env.NODE_ENV === 'development') {
    reverbFilterUri ='http://reverbjs.org/Library/LadyChapelStAlbansCathedral.m4a'
} else {
    reverbFilterUri = config.reverb_impulse_response
}

class ReverbFilter {
    constructor(audioCtx, wetDryMix = 0.0) {
        this._wetDryMix = wetDryMix;
        this._fbAmt = 0.0;

        this.convolveNode = audioCtx.createConvolver();
        axios(reverbFilterUri, {responseType: 'arraybuffer'})
            .then(res => {
                audioCtx.decodeAudioData(res.data, (audioBuffer) => {
                    console.log("Finished decoding audio data");
                    this.convolveNode.buffer = audioBuffer;
                }, console.error);
            })
            .catch(console.error);

        this.inputGain = audioCtx.createGain();
        this.inputGain.gain.value = 1.0;

        this.passGain = audioCtx.createGain();
        this.passGain.gain.value = 1 - this._wetDryMix;

        this.reverbGain = audioCtx.createGain();
        this.reverbGain.gain.value = this._wetDryMix;

        this.outputGain = audioCtx.createGain();
        this.outputGain.gain.value = 1.0;

        this.delayNode = audioCtx.createDelay();
        this.delayNode.delayTime.value = 0.05;

        this.fbNode = audioCtx.createGain();
        this.fbNode.gain.value = this._fbAmt;

        this.convolveNode.connect(this.delayNode);
        this.delayNode.connect(this.fbNode);
        this.fbNode.connect(this.convolveNode);

        this.inputGain.connect(this.passGain);
        this.passGain.connect(this.outputGain);

        this.inputGain.connect(this.convolveNode);
        this.convolveNode.connect(this.reverbGain);
        this.reverbGain.connect(this.outputGain);

    }

    get source() {
        return this.inputGain;
    }

    get destination() {
        return this.outputGain;
    }

    get wetDryMix() {
        return this._wetDryMix;
    }

    set wetDryMix(value) {
        this._wetDryMix = value;
        this.passGain.gain.value = 1 - value;
        this.reverbGain.gain.value = value;
    }

    get fbAmt() {
        return this._fbAmt;
    }

    set fbAmt(value) {
        this._fbAmt = value;
        this.fbNode.gain.value = value;
    }

    set x(value) {
        this.wetDryMix = value;
    }

    set y(value) {
        this.fbAmt = value * 0.3;
    }

    getDefaultPosition() {
        return {x: 0.0, y: 0.0};
    }

    clear() {
        this.x = 0.0;
        this.y = 0.0;
    }
}

export default ReverbFilter;
