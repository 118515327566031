import React, {useEffect, useRef} from 'react';
import MdEventEmitter from "../MdEventEmitter";
import FxColors from "../../MassivelyDistributed/Helpers/FxColors";

const EffectsCanvas = (props) => {
    const canvasRef = useRef();
    let indicatorSwitchRef = useRef([false, false, false, false]);
    let indicatorColorRef = useRef('rgba(255, 255, 255, 1.0)');
    const lastTransportPosition = useRef(0);
    const transportTickWidth = useRef(0);
    const recordStateRef = useRef(false);
    const mouseDownRef = useRef(false);

    if (props.fxLastPosition.current === null) {
        props.fxLastPosition.current = {0: {x: 0, y: -1}, 1: {x: 0, y: -1}, 2: {x: 0, y: -1}, 3: {x: 0, y: -1}};
    }

    useEffect(() => {
        let cb = MdEventEmitter.subscribe("recordStateChange", (data) => {
            recordStateRef.current = data.recordState;
        });
        return () => {MdEventEmitter.unsubscribe('recordStateChange', cb)};
    }, []);

    const convertNormCoordsToPixelCoords = (normalizedCoords) => {
        return {x: normalizedCoords.x * canvasRef.current.width, y: normalizedCoords.y * canvasRef.current.height};
    }

    const getKnobColor = (knobIdx) => {
        return `rgba(${FxColors[knobIdx].r}, ${FxColors[knobIdx].g}, ${FxColors[knobIdx].b}, 1.0)`;
    }

    const getCombineKnobColor = () => {
        let r=0, g=0, b=0;
        let numActive = 0;
        for (let s in props.fxSelected) {
            if (props.fxSelected[s]) {
                numActive++;
                r += FxColors[s].r;
                g += FxColors[s].g;
                b += FxColors[s].b;
            }
        }

        if (numActive === 0) {
            r = 255;
            g = 255;
            b = 255;
        } else {
            r = r / numActive;
            g = g / numActive;
            b = b / numActive;
        }
        return `rgba(${r}, ${g}, ${b}, 1.0)`;
    }

    useEffect(() => {
        indicatorColorRef.current = getCombineKnobColor();
        indicatorSwitchRef.current = props.fxSelected;
    }, [props.fxSelected])

    const drawPosition = (p, color, withCrossHatch = true, radius = 18) => {
        if (!p.hasOwnProperty("x") || !p.hasOwnProperty("y")) return;
        let ctx = canvasRef.current.getContext('2d');

        if(!p.hasOwnProperty('raw_x') || !p.hasOwnProperty('raw_y')) {

            let rawCoords = convertNormCoordsToPixelCoords(p);
            p['raw_x'] = rawCoords.x;
            p['raw_y'] = rawCoords.y;
        }

        ctx.fillStyle = color;
        ctx.strokeStyle = color;

        if (withCrossHatch) {
            ctx.beginPath();
            ctx.moveTo(p.raw_x, 0);
            ctx.lineTo(p.raw_x, canvasRef.current.height);
            ctx.moveTo(0, p.raw_y);
            ctx.lineTo(canvasRef.current.width, p.raw_y);
            ctx.stroke();
        }

        ctx.beginPath();
        ctx.arc(p.raw_x, p.raw_y, radius, 0, 360);
        ctx.fill();
    };

    const setupListeners = () => {
        const getTouchPos = (touchEvent) => {
            let r = canvasRef.current.getBoundingClientRect();
            let x = touchEvent.touches[0].clientX - r.left;
            let y = touchEvent.touches[0].clientY - r.top;

            if (x > canvasRef.current.width) {
                x = canvasRef.current.width;
            } else if (x < 0) {
                x = 0;
            }

            if (y > canvasRef.current.height) {
                y = canvasRef.current.height;
            } else if (y < 0) {
                y = 0;
            }
            return {x: x, y: y};
        }

        const drawTouchKnob = (e) => {
            e.preventDefault();
            let p = getTouchPos(e);

            let preppedPositions = {
                x: p.x / canvasRef.current.width,
                y: p.y / canvasRef.current.height,
                raw_x: p.x,
                raw_y: p.y,
            }

            console.log(props.fxLastPosition);
            props.fxLastPosition.current = updateLastPositions(preppedPositions, indicatorSwitchRef.current, props.fxLastPosition.current);
            drawLastPositions();

            MdEventEmitter.dispatch('fxEvent', {
                indices: indicatorSwitchRef.current,
                ...preppedPositions,
            });
        }

        const drawMouseKnob = (e) => {
            e.preventDefault();

            if (mouseDownRef.current) {
                let r = canvasRef.current.getBoundingClientRect();
                let p = {x: e.clientX - r.left, y: e.clientY - r.top};

                let preppedPositions = {
                    x: p.x / canvasRef.current.width,
                    y: p.y / canvasRef.current.height,
                    raw_x: p.x,
                    raw_y: p.y,
                }


                props.fxLastPosition.current = updateLastPositions(preppedPositions, indicatorSwitchRef.current, props.fxLastPosition.current);
                drawLastPositions();

                MdEventEmitter.dispatch('fxEvent', {
                    indices: indicatorSwitchRef.current,
                    ...preppedPositions,
                });
            }
        }

        let l1 = canvasRef.current.addEventListener('touchstart', drawTouchKnob);
        let l2 = canvasRef.current.addEventListener('touchmove', drawTouchKnob);
        let l3 = canvasRef.current.addEventListener('mousedown', e => {
            mouseDownRef.current = true;
            drawMouseKnob(e);
        });
        let l4 = canvasRef.current.addEventListener('mousemove', drawMouseKnob);
        let l5 = canvasRef.current.addEventListener('mouseup', e => {mouseDownRef.current = false;})

        return () => {
            canvasRef.current.removeEventListener('touchstart', l1);
            canvasRef.current.removeEventListener('touchmove', l2);
            canvasRef.current.removeEventListener('mousedown', l3);
            canvasRef.current.removeEventListener('mousemove', l4);
            canvasRef.current.removeEventListener('mouseup', l5);
        }
    }

    useEffect(() => {
        canvasRef.current.width = props.width;
        canvasRef.current.height = props.height;
        clearCanvas()
        transportTickWidth.current = canvasRef.current.width / 16.0;
    }, [props.width, props.height]);

    const updateLastPositions = (newPosition, updateArray, lastPosArray) => {
        let newLastPosArray = lastPosArray;
        for (let i in updateArray) {
            if (updateArray[i]) {
                newLastPosArray[i] = newPosition;
            }
        }
        return newLastPosArray;
    }

    const drawTransport = (transportPosition) => {
        let ctx = canvasRef.current.getContext('2d');

        ctx.fillStyle = 'rgba(255, 0, 0, 0.2)';
        ctx.strokeStyle = 'rgba(255, 0, 0, 1.0)';

        ctx.beginPath();
        ctx.moveTo(transportPosition, 0);
        ctx.lineTo(transportPosition, canvasRef.current.height);
        ctx.stroke();

        if (recordStateRef.current) {
            ctx.beginPath();
            ctx.moveTo(0, 0);
            ctx.rect(0, 0, transportPosition, canvasRef.current.height);
            ctx.fill();
        }
    }

    const drawLastPositions = (clearAlpha = 1.0) => {
        clearCanvas(clearAlpha);
        drawTransport(lastTransportPosition.current);
        for (let x in props.fxLastPosition.current) {
            drawPosition(props.fxLastPosition.current[x], getKnobColor(x));
        }
    }

    useEffect(() => {
        let cb = MdEventEmitter.subscribe("setFxLastPosition", data => {
            for (let i in data) {
                props.fxLastPosition.current[i] = convertNormCoordsToPixelCoords(data[i]);
            }
            drawLastPositions(1.0);
        });

        let fxpe_cb = MdEventEmitter.subscribe('fx-playback-event', (data) => {
            for (let i in data.fxState) {
                props.fxLastPosition.current[i] = data.fxState[i];
            }
            drawLastPositions(1.0);
        })

        let t_cb = MdEventEmitter.subscribe('transportPositionUpdate', data => {
            lastTransportPosition.current = canvasRef.current.width * (((data.position / data.measureLength) / data.loopLength) + (data.currentMeasure / data.loopLength));
            drawLastPositions();
            // let posIdx = (data.currentMeasure * data.measureLength) + data.position;
            if (recordStateRef.current) {
                let fxRecordBlock = {
                    position: data.fxIdx,
                    fxState: {},
                }

                for (let i in indicatorSwitchRef.current) {
                    if (indicatorSwitchRef.current[i]) {
                        fxRecordBlock.fxState[i] = props.fxLastPosition.current[i];
                    }
                }
                MdEventEmitter.dispatch('fxRecordEvent', fxRecordBlock);
            }
        });

        return () => {
            MdEventEmitter.unsubscribe("setFxLastPosition", cb);
            MdEventEmitter.unsubscribe('transportPositionUpdate', t_cb);
            MdEventEmitter.unsubscribe('fx-playback-event', fxpe_cb);
        }
    }, [])

    useEffect(() => {
        clearCanvas();
        drawLastPositions();
        let removerFunction = setupListeners();
        return () => {removerFunction();}
    }, []);

    const clearCanvas = (alpha = 1.0) => {
        let ctx = canvasRef.current.getContext('2d');
        ctx.fillStyle = `rgba(0,0,0,${alpha})`;
        ctx.fillRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    }

    return (
        <canvas ref={canvasRef} width={400} height={100} aria-label={"Draw here to apply effects."}>
            Canvas is not supported here.
        </canvas>
    )
}

export default EffectsCanvas;
