import React, {useState} from 'react';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createMuiTheme, makeStyles, ThemeProvider, Grid, Slider, Button, TextField, Typography } from '@material-ui/core';
import TimerIcon from '@material-ui/icons/Timer';
import config from "../../MassivelyDistributed/Configs/config";
import { faHourglassHalf } from "@fortawesome/free-solid-svg-icons";
import MdEventEmitter from "../MdEventEmitter";
import {MozToggleFullscreen} from "../../MassivelyDistributed/Helpers/MozFullscreenHelper";
import {Checkbox} from "@material-ui/core";


const textFieldTheme = createMuiTheme({
    overrides: {
        MuiTextField: {
            root: {
                // "backgroundColor": "rgba(255, 255, 255, 1.0)"
            }
        },
        MuiOutlinedInput: {
            root: {
                "backgroundColor": "rgba(255, 255, 255, 1.0)"
            }
        },
        MuiCheckbox: {
            root: {
                "color": "rgba(255, 255, 255, 1.0)"
            }
        },
    }
});

const playControlsSliderTheme = makeStyles({
    root: {
        padding: "0px 0",
        color: "#888888",
    },
    thumb: {
        width: "12px",
        height: "28px",
        "margin-top": "-14px",
        "border-radius": 0,
        "background_color": "#ffffff",
    }
});

const MdSettingsUi = (props) => {
    const [fullscreenState, setFullscreenState] = useState(false);
    const sliderStyle = playControlsSliderTheme();
    const [shareActive, setShareActive] = useState(false);
    const [compositionTitle, setCompositionTitle] = useState('');
    const [authorName, setAuthorName] = useState('');
    const [allowGeolocation, setAllowGeolocation] = useState(false);
    const [geolocationDat, setGeolocationDat] = useState(null);

    const getFullscreenButton = () => {
        if (/iPhone|iPod/i.test(navigator.userAgent)) {
            return null
        }
        else {
            return (
                <Grid item xs={8}>
                    <Button variant={"contained"}
                            fullWidth
                            onClick={() => {
                                setFullscreenState(!fullscreenState);
                                MozToggleFullscreen();
                            }}
                            aria-label={"Toggle Fullscreen."}
                    >FULL SCREEN</Button>
                </Grid>
            )
        }
    }

    const getShareUi = () => {
        return (
            <Grid container>
                <Grid container direction={"row"}
                      justify={"center"}
                      alignItems={"center"}
                      >
                    <Grid item xs={8}>
                        <Button variant={"contained"}
                                fullWidth
                                onClick={() => {
                                    setShareActive(false);
                                }}
                                aria-label={"Cancel share / submission."}
                            >Back</Button>
                    </Grid>
                    <Grid container
                          direction={"row"}
                          justify={"center"}
                          alignItems={"center"}
                          >
                        <Grid item xs={2} className={"submissionFormText"}>
                            <Typography variant={"button"}>Title</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <ThemeProvider theme={textFieldTheme}>
                                <TextField
                                    variant={"outlined"}
                                    color={"primary"}
                                    value={compositionTitle}
                                    onChange={(e) => {
                                        setCompositionTitle(e.target.value);
                                    }}
                                    aria-label={"Enter the title for your composition."}
                                />
                            </ThemeProvider>
                        </Grid>
                    </Grid>
                    <Grid container
                          direction={"row"}
                          justify={"center"}
                          alignItems={"center"}
                          >
                        <Grid item xs={2} className={"submissionFormText"}>
                            <Typography variant={"button"}>Author</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <ThemeProvider theme={textFieldTheme}>
                                <TextField
                                    variant={"outlined"}
                                    color={"primary"}
                                    value={authorName}
                                    onChange={(e) => {
                                        setAuthorName(e.target.value);
                                    }}
                                    aria-label={"Enter your name (author / DJ / artist / stage name)."}
                                />
                            </ThemeProvider>
                        </Grid>
                    </Grid>
                    <Grid container
                          direction={"row"}
                          justify={"center"}
                          alignItems={"center"}
                          >
                        <Grid item xs={6} className={"submissionFormText"}>
                            <Typography variant={"button"}>Allow Location Data</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <ThemeProvider theme={textFieldTheme}>
                                <Checkbox checked={allowGeolocation} onChange={(e)=> {
                                    setAllowGeolocation(e.target.checked);

                                    if (e.target.checked) {
                                        navigator.geolocation.getCurrentPosition((data) => {
                                            setGeolocationDat({
                                                accuracy: data.coords.accuracy,
                                                latitude: data.coords.latitude,
                                                longitude: data.coords.longitude,
                                                heading: data.coords.heading,
                                                speed: data.coords.speed,
                                                altitude: data.coords.altitude,
                                                altitudeAccuracy: data.coords.altitudeAccuracy
                                            });
                                        }, console.error, {enableHighAccuracy: true, timeout: 5000, maximumAge: 0});
                                    } else {
                                        setGeolocationDat(null);
                                    }
                                }}
                                aria-label={"Check to share your geolocation when you submit."}
                                />
                            </ThemeProvider>
                        </Grid>
                    </Grid>
                    <Grid item xs={8}>
                        <Button
                            variant={"contained"}
                            fullWidth
                            onClick={() => {
                                MdEventEmitter.dispatch('submit-composition', {submit: true, title: compositionTitle, created_by: authorName, geolocation: geolocationDat});
                                setShareActive(false);
                                setAuthorName('');
                                setCompositionTitle('');
                                setAllowGeolocation(false);
                                setGeolocationDat(null);
                            }}
                            aria-label={"Clikc here to submit your composition"}
                        >SUBMIT</Button>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    const getSettingsUi = () => {
        return (
            <Grid container>
                <Grid container direction={"row"}
                      justify={"center"}
                      alignItems={"center"}
                      // xs={12}
                >
                        <Grid container
                              // xs={12}
                              direction={"row"}
                              justify={"center"}
                              alignItems={"center"}
                              >
                            <Grid item xs={2}>
                                <span className={"mui-transport-icons-active"}>
                                    {/*<FontAwesomeIcon icon={faTachometerAlt} />*/}
                                    <TimerIcon />
                                </span>
                            </Grid>
                            <Grid item xs={6}>
                                <Slider
                                    value={props.tempo}
                                    step={null}
                                    marks={config.allowed_values.tempo}
                                    min={50}
                                    max={190}
                                    onChange={(e,v) => {
                                        props.setTempo(v);
                                    }}
                                    valueLabelDisplay={"auto"}
                                    classes={{root: sliderStyle.root, thumb: sliderStyle.thumb}}
                                    aria-label={"Adjust tempo."}
                                />
                            </Grid>
                        </Grid>

                        <Grid container
                              direction={"row"}
                              justify={"center"}
                              alignItems={"center"}
                              // xs={12}
                              >
                            <Grid item xs={2}>
                                <span className={"fa-transport-icons-active"}>
                                    <FontAwesomeIcon icon={faHourglassHalf} />
                                </span>
                            </Grid>
                            <Grid item xs={6}>
                                <Slider
                                    value={props.compLength}
                                    step={null}
                                    marks={config.allowed_values.compLength}
                                    min={8}
                                    max={17}
                                    onChange={(e, v) => {
                                        props.setCompLength(v);
                                    }}
                                    valueLabelDisplay={"auto"}
                                    classes={{root: sliderStyle.root, thumb: sliderStyle.thumb}}
                                    aria-label={"Adjust composition length (number of beats)."}
                                />
                        </Grid>
                    </Grid>
                    {getFullscreenButton()}
                    <Grid item xs={8}>
                        <Button
                            variant={"contained"}
                            fullWidth
                            onClick={() => {
                                // MdEventEmitter.dispatch('submit-composition', {submit: true, title: compositionTitle, created_by: authorName});
                                setShareActive(true);
                            }}
                            aria-label={"Click to submit composition."}
                        >SUBMIT</Button>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    return (
        <Grid container className={'settings-ui'}>
            {(shareActive) ? getShareUi() : getSettingsUi()}
        </Grid>
    )
}

export default MdSettingsUi;
