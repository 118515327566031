import React, {useEffect, useRef} from 'react';
import MdEventEmitter from "../MdEventEmitter";

const MdSequencerButton = (props) => {
    let mySampleNumber = ((props.currentPack * 12) + (props.myRow));


    const buttonChange = (e) => {
        let idx = props.currentPack;
        let tPack = {...props.compState[props.currentPack]};
        tPack[props.myRow][props.myId] = !tPack[props.myRow][props.myId];
        let newCompState = {...props.compState};
        newCompState[idx] = tPack;
        props.setCompState(newCompState);
    }
    const checkIsActive = () => {
        return (props.compState[props.currentPack][props.myRow][props.myId]);
    }

    const getClassName = () => {
        let isActive = checkIsActive();

        if (props.myId >= props.compLength) {
            if (isActive) {
                return `sequencer-button-${props.numButtons} sequencer-button sequencer-button-disabled-active`
            } else {
                return `sequencer-button-${props.numButtons} sequencer-button sequencer-button-disabled`
            }
        } else {
            if (isActive) {
                return `sequencer-button-${props.numButtons} sequencer-button sequencer-button-pack-${props.currentPack}-active`;
            } else {
                return `sequencer-button-${props.numButtons} sequencer-button sequencer-button-pack-${props.currentPack}`;
            }
        }
    }

    return (
        <button
            onClick={buttonChange}
            className={getClassName()}
            aria-label={`Toggle sample ${mySampleNumber} at beat ${props.myId}`}
                />
    )
}

const MdSequencerRow = (props) => {
    return (
        <div className={`sequencer-row sequencer-row-${props.rowSize}`}>
            {Array(props.numButtons).fill().map((_, i) => {
                return <MdSequencerButton currentPack={props.currentPack} numButtons={props.numButtons} key={i} myId={i} compState={props.compState} setCompState={props.setCompState} myRow={props.myRow} compLength={props.compLength}/>
            })}
        </div>
        )

}

const transportColor = 'rgba(249, 152, 49, 0.75)';

const MdSequencerUi = (props) => {
    const seqDivRef = useRef();
    const seqCanvas = useRef();
    const sixteenthWidth = useRef(0);

    const DrawTransportAtBeat = (_beat) => {
        let tCtx = seqCanvas.current.getContext('2d');
        tCtx.beginPath();
        tCtx.fillStyle = 'rgb(0,0,0)';
        tCtx.rect(0, 0, seqCanvas.current.width, seqCanvas.current.height);
        tCtx.fill();
        tCtx.beginPath();
        tCtx.fillStyle = transportColor;
        tCtx.rect((_beat) * sixteenthWidth.current, 0, sixteenthWidth.current*0.15, seqCanvas.current.height);
        tCtx.fill();
    }

    useEffect(() => {
        sixteenthWidth.current = seqCanvas.current.width / 16.0;
        DrawTransportAtBeat(0);
        let cb = MdEventEmitter.subscribe('transportPositionUpdate', (data) => {DrawTransportAtBeat(data.position - 1);});
        return () => {MdEventEmitter.unsubscribe('transportPositionUpdate', cb)};
    }, []);

    return (
        <div ref={seqDivRef} className={"md-sequencer"}>
            <canvas ref={seqCanvas}>
                Canvas invalid here.
            </canvas>
            <div className={"md-sequencer-overlay"}>
            {Array(12).fill().map((_, i) => {
                return <MdSequencerRow key={`row-${i}`} myRow={i} currentPack={props.currentPack} compState={props.compState} setCompState={props.setCompState} rowSize={12} numButtons={16} compLength={props.compLength} />
            })}
            </div>
        </div>
    )
}

export default MdSequencerUi;
