import React, {useEffect, useState} from 'react';

import p1s1 from './SampleImages_HC/P1/P1_S1_1.png';
import p1s2 from './SampleImages_HC/P1/P1_S2_1.png';
import p1s3 from './SampleImages_HC/P1/P1_S3_1.png';
import p1s4 from './SampleImages_HC/P1/P1_S4_1.png';
import p1s5 from './SampleImages_HC/P1/P1_S5_1.png';
import p1s6 from './SampleImages_HC/P1/P1_S6_1.png';
import p1s7 from './SampleImages_HC/P1/P1_S7_1.png';
import p1s8 from './SampleImages_HC/P1/P1_S8_1.png';
import p1s9 from './SampleImages_HC/P1/P1_S9_1.png';
import p1s10 from './SampleImages_HC/P1/P1_S10_1.png';
import p1s11 from './SampleImages_HC/P1/P1_S11_1.png';
import p1s12 from './SampleImages_HC/P1/P1_S12_1.png';

import p2s1 from './SampleImages_HC/P2/P2_S1_1.png';
import p2s2 from './SampleImages_HC/P2/P2_S2_1.png';
import p2s3 from './SampleImages_HC/P2/P2_S3_1.png';
import p2s4 from './SampleImages_HC/P2/P2_S4_1.png';
import p2s5 from './SampleImages_HC/P2/P2_S5_1.png';
import p2s6 from './SampleImages_HC/P2/P2_S6_1.png';
import p2s7 from './SampleImages_HC/P2/P2_S7_1.png';
import p2s8 from './SampleImages_HC/P2/P2_S8_1.png';
import p2s9 from './SampleImages_HC/P2/P2_S9_1.png';
import p2s10 from './SampleImages_HC/P2/P2_S10_1.png';
import p2s11 from './SampleImages_HC/P2/P2_S11_1.png';
import p2s12 from './SampleImages_HC/P2/P2_S12_1.png';


import p3s1 from './SampleImages_HC/P3/P3_S1_1.png';
import p3s2 from './SampleImages_HC/P3/P3_S2_1.png';
import p3s3 from './SampleImages_HC/P3/P3_S3_1.png';
import p3s4 from './SampleImages_HC/P3/P3_S4_1.png';
import p3s5 from './SampleImages_HC/P3/P3_S5_1.png';
import p3s6 from './SampleImages_HC/P3/P3_S6_1.png';
import p3s7 from './SampleImages_HC/P3/P3_S7_1.png';
import p3s8 from './SampleImages_HC/P3/P3_S8_1.png';
import p3s9 from './SampleImages_HC/P3/P3_S9_1.png';
import p3s10 from './SampleImages_HC/P3/P3_S10_1.png';
import p3s11 from './SampleImages_HC/P3/P3_S11_1.png';
import p3s12 from './SampleImages_HC/P3/P3_S12_1.png';


import Tabs from "@material-ui/core/Tabs";
import Tab from '@material-ui/core/Tab';

import sampleTsv from '../SampleInfoFiles/MD_HC_SamplesInfoDetails.tsv';
// import sampleTsv from '../SampleInfoFiles/MD_Pack Sheet for Copy_10312020.tsv';
import Papa from 'papaparse';

import Collapse from "@material-ui/core/Collapse";

function createRow(pack, sampleName, videoSampleName, videoSampleDescription, audioSampleName, audioSampleDescription) {
    return { pack, sampleName, videoSampleName, videoSampleDescription, audioSampleName, audioSampleDescription };
}

const DisplayPackRow = (props) => {
    return (
        <div className={"sample-images-row"}>
            <img
                src={props.packImages[0]}
                className={(props.selectedSamples[(props.packRowIdx*4)]) ? "sample-img sample-img-active" : "sample-img sample-img-inactive"}
                onClick={() => props.sampleSelector((props.packRowIdx*4))}
                aria-label={`Get info for sample ${props.packRowIdx*4}`}
            />
            <img
                src={props.packImages[1]}
                className={(props.selectedSamples[(props.packRowIdx*4)+1]) ? "sample-img sample-img-active" : "sample-img sample-img-inactive"}
                onClick={() => props.sampleSelector((props.packRowIdx*4)+1)}
                aria-label={`Get info for sample ${(props.packRowIdx*4)+1}`}
            />
            <img
                src={props.packImages[2]}
                className={(props.selectedSamples[(props.packRowIdx*4)+2]) ? "sample-img sample-img-active" : "sample-img sample-img-inactive"}
                onClick={() => props.sampleSelector((props.packRowIdx*4)+2)}
                aria-label={`Get info for sample ${(props.packRowIdx*4)+2}`}
            />
            <img
                src={props.packImages[3]}
                className={(props.selectedSamples[(props.packRowIdx*4)+3]) ? "sample-img sample-img-active" : "sample-img sample-img-inactive"}
                onClick={() => props.sampleSelector((props.packRowIdx*4)+3)}
                aria-label={`Get info for sample ${(props.packRowIdx*4)+3}`}
            />
        </div>
    )
}

const DisplayPack = (props) => {
    let sampleRows = [];
    for (let i = 0 ; i < props.packImages.length; i+=4) {
        let e;
        if (i+4 <= props.packImages.length ) {
            e = i+4;
        } else {
            e = props.packImages.length - 1;
        }
        sampleRows.push(props.packImages.slice(i, e));
    }
    return (
        <>
            {sampleRows.map((r, idx) => (
                <DisplayPackRow key={idx} packImages={sampleRows[idx]} packRowIdx={idx} sampleSelector={props.sampleSelector} selectedSamples={props.selectedSamples}/>
            ))}
        </>
    )
}

function selectedSamplesStarter() {
    let a = [];
    for (let i = 0; i<12; i++) {
        a.push(false);
    }
    return a;
}


const SampleDetails_HC = (props) => {
    const [selectedPack, setSelectedPack] = useState(0);
    const [selectedSamples, setSelectedSamples] = useState(selectedSamplesStarter());
    const [sampleInfo, setSampleInfo] = useState([]);

    const [showSampleInfo, setShowSampleInfo] = useState(false);
    const [currentSampleInfo, setCurrentSampleInfo] = useState({});

    useEffect(() => {
        fetch(sampleTsv)
            .then(r => r.text())
            .then(r => {
                let a = Papa.parse(r, {delimiter: ";"});
                let p1 = [],
                    p2 = [],
                    p3 = [];

                console.log(a);

                for (let e of a.data) {
                    if (e.length > 1) {
                        let tRow = createRow(e[0], e[1], e[2], e[3], e[4], e[5]);
                        switch(e[0]) {
                            case "PACK 1":
                                p1.push(tRow);
                                break;
                            case "PACK 2":
                                p2.push(tRow);
                                break;
                            case "PACK 3":
                                p3.push(tRow);
                                break;
                            default:
                                console.error("Error parsing sample info");
                                break;
                        }
                    }
                }
                setSampleInfo([p1, p2, p3]);
                setCurrentSampleInfo(p1[0]);
            });
    }, []);


    const activateSampleByIdx = (idx) => {
        let a = selectedSamplesStarter();

        if(selectedPack === 0) {
            if(idx <= 3) {
                setCurrentSampleInfo(sampleInfo[0][0]);
                for (let x=0; x<=3; x++) {
                    a[x] = true;
                }
            } else {
                setCurrentSampleInfo(sampleInfo[0][idx-3]);
            }
        } else if(selectedPack === 1) {
            if (idx <= 4) {
                setCurrentSampleInfo(sampleInfo[1][0]);
                for (let x=0; x<=4; x++) {
                    a[x] = true;
                }
            } else {
                setCurrentSampleInfo(sampleInfo[1][idx-4]);
            }
        } else if(selectedPack === 2) {
            setCurrentSampleInfo(sampleInfo[2][idx]);
        }

        a[idx] = true;
        setSelectedSamples(a);
        setShowSampleInfo(true);
    }

    function getCurrentPack() {
        let r = []
        switch (selectedPack) {
            case 0:
                r = [p1s1, p1s2, p1s3, p1s4, p1s5, p1s6, p1s7, p1s8, p1s9, p1s10, p1s11, p1s12];
                break;
            case 1:
                r = [p2s1, p2s2, p2s3, p2s4, p2s5, p2s6, p2s7, p2s8, p2s9, p2s10, p2s11, p2s12];
                break;
            case 2:
                r = [p3s1, p3s2, p3s3, p3s4, p3s5, p3s6, p3s7, p3s8, p3s9, p3s10, p3s11, p3s12];
                break;
        }
        return r;
    }

    return (
        <div style={{"paddingBottom": "10px"}} aria-label={"Sample Information"}>
            <Tabs
                value={selectedPack}
                onChange={(e, nv) => {
                    setSelectedPack(nv);
                    setSelectedSamples(selectedSamplesStarter());
                    setShowSampleInfo(false);
                }}
                centered
                >
                <Tab label={"Pack 1"} aria-label={"Sample Pack 1"}/>
                <Tab label={"Pack 2"} aria-label={"Sample Pack 2"}/>
                <Tab label={"Pack 3"} aria-label={"Sample Pack 3"}/>
            </Tabs>
            <DisplayPack packImages={getCurrentPack()} sampleSelector={activateSampleByIdx} selectedSamples={selectedSamples}/>
            <Collapse in={showSampleInfo} timeout={"auto"} unmountOnExit>
                <h3>Details</h3>
                <h4>Audio</h4>
                <strong>Name: {currentSampleInfo.audioSampleName}</strong>
                <div>{currentSampleInfo.audioSampleDescription}</div>
                <h4>Video</h4>
                <strong>Name: {currentSampleInfo.videoSampleName}</strong>
                <div>{currentSampleInfo.videoSampleDescription}</div>
            </Collapse>
        </div>
    )
}

export default SampleDetails_HC;
