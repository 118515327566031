import React, {useEffect, useRef, useState} from 'react';
import EffectsCanvas from "./EffectsCanvas";

const MdEffectsUi = (props) => {
    const divRef = useRef();
    const [divWidth, setDivWidth] = useState(null);
    const [divHeight, setDivHeight] = useState(null);

    useEffect(() => {
        setDivWidth(divRef.current.clientWidth);
        setDivHeight(divRef.current.clientHeight);
    }, []);

    const getCanvas = () => {
        return <EffectsCanvas width={divWidth} height={divHeight} fxSelected={props.fxSelected} fxLastPosition={props.fxLastPosition}/>
    }

    return (
        <div className={"md-effects-ui-div"} ref={divRef}>
            {getCanvas()}
        </div>
    )
}

export default MdEffectsUi;
