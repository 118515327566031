import React from 'react';
import MdEventEmitter from "../MdEventEmitter";

const MdDrumpadButton = (props) => {
    const tClassName = "drumpad-button drumpad-button-4 pack-theme-" + (props.currentPack+1);
    const triggerId = (props.currentPack * 12) + (props.rowNum * 4) + props.buttonIdx;
    return (
        <button className={tClassName} onClick={() => {
            MdEventEmitter.dispatch('drumpad_toggle', {
                triggerId: triggerId
            });
        }}
                aria-label={`Activate sample ${triggerId}`}
        />
    )
}

const MdDrumpadRow = (props) => {
    return (
        <div className={`drumpad-row drumpad-row-${props.rowSize}`}>
            <MdDrumpadButton currentPack={props.currentPack} rowNum={props.rowNum} buttonIdx={0}/>
            <MdDrumpadButton currentPack={props.currentPack} rowNum={props.rowNum} buttonIdx={1}/>
            <MdDrumpadButton currentPack={props.currentPack} rowNum={props.rowNum} buttonIdx={2}/>
            <MdDrumpadButton currentPack={props.currentPack} rowNum={props.rowNum} buttonIdx={3}/>
        </div>
    )
}

const MdDrumpadUi = (props) => {
    return (
        <div className={'drumpad'}>
            <MdDrumpadRow currentPack={props.currentPack} rowSize={3} rowNum={0}/>
            <MdDrumpadRow currentPack={props.currentPack} rowSize={3} rowNum={1}/>
            <MdDrumpadRow currentPack={props.currentPack} rowSize={3} rowNum={2}/>
        </div>
    )
}

export default MdDrumpadUi;
