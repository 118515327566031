class DelayFilter {
    constructor(audioCtx, tempo=120, tempoSubdiv=2, feedbackAmt = 0.7, wetDryMix = 0.0) {
        this._delayTime = 2.0;

        this._feedbackAmt = feedbackAmt;
        this._wetDryMix = wetDryMix;

        this._tempoSubdiv = tempoSubdiv; // Should be [1, 2, 3, 4]; 1 = 1/4 note, 2 = 1/8
        this._tempo = tempo;

        this.inputGain = audioCtx.createGain();
        this.inputGain.gain.value = 1.0;

        this.delayNode = audioCtx.createDelay();
        this.delayNode.delayTime.value = this._delayTime;

        this.fbNode = audioCtx.createGain();
        this.fbNode.gain.value = this._feedbackAmt;

        this.delayGain = audioCtx.createGain();
        this.delayGain.gain.value = this._wetDryMix;

        this.passGain  = audioCtx.createGain();
        this.passGain.gain.value = 1 - this._wetDryMix;

        this.output = audioCtx.createGain();

        this.inputGain.connect(this.delayNode);
        this.delayNode.connect(this.fbNode);
        this.fbNode.connect(this.delayNode);
        this.delayNode.connect(this.delayGain);
        this.delayGain.connect(this.output);

        this.inputGain.connect(this.passGain);
        this.passGain.connect(this.output);

        this.tempo = tempo;
    }

    get source() {
        return this.inputGain;
    }

    get destination() {
        return this.output;
    }

    get delayTime() {
        return this._delayTime;
    }

    set delayTime(value) {
        this._delayTime = value;
        this.delayNode.delayTime.value = value;
    }

    get feedbackAmt() {
        return this._feedbackAmt;
    }

    set feedbackAmt(value) {
        this._feedbackAmt = value;
        this.fbNode.gain.value = value;
    }

    get wetDryMix() {
        return this._wetDryMix;
    }

    set wetDryMix(value) {
        this._wetDryMix = value;
        this.delayGain.gain.value = value;
        this.passGain.gain.value = 1 - value;
    }

    getDefaultPosition() {
        return {x: 0.0, y: 1.0};
    }

    set x(value) {
        this.feedbackAmt = value;
    }

    set y(value) {
        this.wetDryMix = 1 - value;
    }

    get tempo() {
        return this._tempo;
    }

    set tempo(value) {
        this._tempo = value;
        this.delayTime = (60 / (value * this._tempoSubdiv));
    }

    get tempoSubdiv() {
        return this._tempoSubdiv;
    }

    set tempoSubdiv(value) {
        this._tempoSubdiv = value;
        this.tempo = this._tempo;
    }

    clear() {
        this.wetDryMix = 0.0;
        this.feedbackAmt = 0.0;
    }
}

export default DelayFilter;
