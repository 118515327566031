import { Filter } from '@pixi/core';
import { defaultVert } from "../defaultVert";

const frag = `
varying vec2 vTextureCoord;
uniform vec2 size;
uniform sampler2D uSampler;
uniform float wetDryMix;
uniform vec4 filterArea;

vec2 mapCoord( vec2 coord )
{
    coord *= filterArea.xy;
    coord += filterArea.zw;
    return coord;
}

vec2 unmapCoord( vec2 coord )
{
    coord -= filterArea.zw;
    coord /= filterArea.xy;
    return coord;
}

vec2 pixelate(vec2 coord, vec2 size)
{
    return floor( coord / size ) * size;
}
void main(void)
{
    vec2 coord = mapCoord(vTextureCoord);
    coord = pixelate(coord, size);
    coord = unmapCoord(coord);

    gl_FragColor = mix(texture2D(uSampler, vTextureCoord), texture2D(uSampler, coord), wetDryMix);
}`



class PixelateWithMixFilter extends Filter {
    constructor(size = 10, wetDryMix = 0.5) {
        super(defaultVert, frag);
        this.size = size;
        this.wetDryMix = wetDryMix;
    }

    get size() {
        return this.uniforms.size;
    }

    set size(value) {
        if (typeof value === 'number') {
            value = [value, value];
        }
        this.uniforms.size = value;
    }

    get wetDryMix() {
        return this.uniforms.wetDryMix;
    }

    set wetDryMix(value) {
        this.uniforms.wetDryMix = value;
    }
}

export { PixelateWithMixFilter };