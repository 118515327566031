import AppBar from "@material-ui/core/AppBar";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faStop, faAlignJustify, faAsterisk, faCog} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {createMuiTheme, makeStyles, ThemeProvider} from "@material-ui/core/styles";


const useStyles = makeStyles({
    bottomNavFooter: {
        bottom: 0,
        top: "auto",
    },
    bottomNav: {
        backgroundColor: "#000000",
    },
})


const makeBottomNavTheme = () => {
    return createMuiTheme({
        overrides: {
            MuiBottomNavigationAction: {
                label: {
                    color: "#ffffff",
                    opacity: 0,
                }
            }
        }
    })
}


const MdBottomNav = (props) => {
    const classes = useStyles();
    return(
        <AppBar position={"fixed"} className={classes.bottomNavFooter}>
            <ThemeProvider theme={makeBottomNavTheme()}>
            <BottomNavigation className={classes.bottomNav} value={props.currentPage} onChange={(e, newValue) => {
                props.setCurrentPage(newValue);
            }}>
                <BottomNavigationAction label={"drumpad"} value={"drumpad"} icon={
                    <span className={(props.currentPage==='drumpad') ? "fa-nav-enabled" : "fa-nav"}>
                            <FontAwesomeIcon icon={faStop} transform={{rotate: 90}}/>
                        </span>}
                />

                <BottomNavigationAction label={"sequencer"} value={"sequencer"} icon={
                    <span className={(props.currentPage==='sequencer') ? "fa-nav-enabled" : "fa-nav"}>
                            <FontAwesomeIcon icon={faAlignJustify}/>
                        </span>}
                />

                <BottomNavigationAction label={"mixer"} value={"mixer"} icon={
                    <span className={(props.currentPage==='mixer') ? "fa-nav-enabled" : "fa-nav"}>
                            <FontAwesomeIcon icon={faAlignJustify} transform={{rotate: 90}}/>
                        </span>}
                />
                <BottomNavigationAction label={"effects"} value={"effects"} icon={
                    <span className={(props.currentPage==='effects') ? "fa-nav-enabled" : 'fa-nav'}>
                            <FontAwesomeIcon icon={faAsterisk}/>
                        </span>}
                />
                <BottomNavigationAction label={"settings"} value={"settings"} icon={
                    <span className={(props.currentPage==='share') ? "fa-nav-enabled" : "fa-nav"}>
                        <FontAwesomeIcon icon={faCog} />
                    </span>
                }
                />
            </BottomNavigation>
            </ThemeProvider>
        </AppBar>
    )
}

export default MdBottomNav;
