const makeEmptyTrack = (numBins=16) => {
    return Array(numBins).fill(false);
}

const makeRandomTrack = (numBins=16, probFill=0.1) => {
    let tTrack = makeEmptyTrack(numBins);
    for (let i = 0; i < numBins; i ++) {
        if (Math.random() < probFill) {
            tTrack[i] = true;
            console.log("fill");
        }
    }
    return tTrack
}

const makeRandomPack = (numTracks = 12, numBins = 16, probFill=0.1) => {
    let templatePack = {};
    for (let i=0; i < numTracks; i ++ ) {templatePack[i] = makeRandomTrack(numBins, probFill);}
    return templatePack;
}

const makeEmptyPack = (numTracks = 12, numBins = 16) => {
    let templatePack = {};
    for (let i =0; i < numTracks; i++) {templatePack[i] = makeEmptyTrack(numBins);}
    return templatePack;
}


export { makeEmptyTrack, makeRandomTrack, makeEmptyPack, makeRandomPack };
