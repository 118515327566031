import React from 'react';
import Box from "@material-ui/core/Box";

const MdPackSelector = (props) => {
    return (
        <Box py={0} height={"49%"} style={{"display": "flex", "marginTop": "1%"}}>
            <button
                onClick={() => {props.setCurrentPack(0)}}
                aria-label={"Press to select Sample Pack 1"}
                className={(props.currentPack===0) ? "pack-select-button pack-select-1-active" : "pack-select-button pack-select-inactive"}>
                <span className={(props.currentPack===0) ? "dot dot-active" : "dot dot-inactive"}/>
            </button>

            <button
                onClick={() => {props.setCurrentPack(1)}}
                aria-label={"Press to select Sample Pack 2"}
                className={(props.currentPack===1) ? "pack-select-button pack-select-2-active" : "pack-select-button pack-select-inactive"}>
                <span className={(props.currentPack===1) ? "dot dot-active" : "dot dot-inactive"}/>
                <span className={(props.currentPack===1) ? "dot dot-active" : "dot dot-inactive"}/>
            </button>

            <button
                onClick={() => {props.setCurrentPack(2)}}
                aria-label={"Press to select Sample Pack 3"}
                className={(props.currentPack===2) ? "pack-select-button pack-select-3-active" : "pack-select-button pack-select-inactive"}>
                <span className={(props.currentPack===2) ? "dot dot-active" : "dot dot-inactive"}/>
                <span className={(props.currentPack===2) ? "dot dot-active" : "dot dot-inactive"}/>
                <span className={(props.currentPack===2) ? "dot dot-active" : "dot dot-inactive"}/>
            </button>
        </Box>
    )
}

export default MdPackSelector;
