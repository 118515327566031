import React, {useRef, useState} from 'react';
import {Box} from '@material-ui/core';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
    faPlay,
    faCircle,
} from "@fortawesome/free-solid-svg-icons";

import ClearIcon from '@material-ui/icons/Clear';

const MdPlayControls = (props) => {
    const [clearAction, setClearAction] = useState(false);
    const clearActionIntervalRef = useRef(null);

    return (
        <Box py={0} height={"49%"} style={{"display": "flex"}}>
            <button
                className={(props.playState) ? "pack-select-button play-button-active" : "pack-select-button pack-select-inactive"}
                onClick={() => {
                    props.setPlayState(!props.playState);
                }}
                aria-label={"Toggle to start/stop playback."}
            >
                <FontAwesomeIcon icon={faPlay} color={(props.playState) ? "green" : "#888888"} />
            </button>
            <button
                className={(props.recordState) ? "pack-select-button record-button-active" : "pack-select-button pack-select-inactive"}
                onClick={() => {
                    props.setRecordState(!props.recordState);
                }}
                aria-label={"Toggle to enable/disable recording"}
            >
                <FontAwesomeIcon icon={faCircle} color={(props.recordState) ? "red" : "#888888"}/>
            </button>
            <button
                className={(clearAction) ? "pack-select-button clear-button-active" : "pack-select-button pack-select-inactive"}
                onClick={() => {
                    props.clearCurrentPack();
                    setClearAction(true);

                    if (clearActionIntervalRef.current) {
                        clearInterval(clearActionIntervalRef.current);
                        clearActionIntervalRef.current = null;
                    }

                    clearActionIntervalRef.current = setInterval(() => {
                        setClearAction(false);
                        clearInterval(clearActionIntervalRef.current);
                        clearActionIntervalRef.current = null;
                    }, 500);
                }}
                aria-label={"Press to clear your composition"}
            >
                <ClearIcon style={{"color": "#888888", "marginTop": "1px"}} />
            </button>
        </Box>
    )
}

export default MdPlayControls;
