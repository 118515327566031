import React from 'react';
import {Container} from '@material-ui/core';
import bannerImg from './images/etc/MD_Learning_header.jpg'
import MdFooter from "./MdFooter";
import { Link } from 'react-router-dom';

const MdLearning = () => {
    return (
        <Container maxWidth={"md"} className={"md-info-pages-container"}>
            <img src={bannerImg} className={"md-image-el"} alt={"learning more about massively distributed"}/>

            <div className={'md-info-div md-info-just'}>
                Massively Distributed has been built to be intuitive, opening access to multimedia creation without prior knowledge in any written, visual or technical language. The MD interfaces are derived from a drum machine, midi sequencer and sound mixing board. This Learning page is here to help anyone that wants to learn more about how to use MD or navigate it more clearly.
            </div>
            <div className={'md-info-div md-info-just'}>
                The video below shows how to use the drum pad, sequencer, mixer, effects, and how to submit a composition to the MD database for public showing.
            </div>

            <div className={"md-trailer"}>
                <iframe title="vimeo-player" src="https://player.vimeo.com/video/469979692" width="640" height="360"
                    frameBorder="0" allowFullScreen></iframe>
            </div>

            <h2>How to Use MD</h2>
            <div className={'md-info-div md-info-just'}>
                More information about how Massively Distributed works and how to use it can be found <Link to={'/how-to'}>HERE</Link>.
            </div>

            {/*<h2>More Info on Submitting</h2>*/}
            {/*<div className={'md-info-div md-info-just'}>*/}
            {/*    The artworks you create in the MD app can be submitted for inclusion in future meta-compositions. MASARY takes these submissions and aggregates them into groups, and they will be shown at each site simultaneously. To the extent possible, our team will announce through SPA, and on this website, the artworks being shown when - so that if you are interested to see your works presented at the scale of a building and through a professional sound system - you get the chance.*/}
            {/*</div>*/}
            <MdFooter />
        </Container>
        )
}


export default MdLearning;
