import React from 'react';
import {Container} from "@material-ui/core";
import {Grid} from "@material-ui/core";
import {Link} from 'react-router-dom';
import MdFooter from "./MdFooter";

import './infoStyles.css';
import md_banner from './images/MD_Banner_2.jpg'
import md_im1 from './images/Landing Page/MD_Site_Landing_1.jpg';
import md_app_img from './images/Landing Page/MD website button_c_with text.jpg';
import md_im2 from './images/masary_md.jpg';

const MdInfoPages = (props) => {
    return (
        <Container maxWidth={"md"} className={"md-info-pages-container"}>
            <div>
                <img src={md_banner} alt={"Massively Distributed - collaborative multimedia instrument from MASARY"} className={"md-image-el"}/>
            </div>
            <div className={"md-info-pages-text-div"}>
                <Grid container>
                    <Grid item xs={6}>
                        <Link to={"/app"}><img src={md_app_img} className={"link-to-app-image"}
                                               alt={"link to massively distributed app"}/></Link>
                    </Grid>
                    <Grid item xs={6}>
                        <div className={"md-info-questions"}>
                            <div className={"md-info-questions-inner"}>
                                <div className={'md-info md-info-left'}>What does it mean to play your city like an
                                    instrument?
                                </div>
                                <div className={'md-info md-info-center'}>How would it sound?</div>
                                <div className={'md-info md-info-right'}>What could it look like?</div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
                <div className={'md-info-div md-info-just'}>
                    Massively Distributed is both a distributed artwork that can be accessed by the public through
                    their personal smartphones and computers and a large-scale public installation featuring
                    compositions created by the public.
                </div>
                <div className={"md-banner-image-div"}>
                    <img src={md_im1} alt={"Massively Distributed 1"}/>
                </div>
                <div className={'md-info-div md-info-just'}>
                    MASARY Studios is a transdisciplinary artist collective using sound, light, interactivity, research,
                    and performance. Their artwork often relies on location and/or interaction to be realized. Based in
                    Boston, the studio's practice includes live percussion performance, electronic music and production,
                    facade projection-mapped video, artificial intelligence, artistic research, technology and materials
                    fabrication, and the expansive use of animation. The studio is artist-owned and managed and was
                    founded in 2015.
                </div>
                <div className={'md-info-div md-info-just'}>
                    Massively Distributed is a culmination of this work and research
                </div>
                <div className={'md-info-div md-info-just'}>
                    <a target={'_blank'} rel={'noopener noreferrer'} href={"https://www.masarystudios.com/"}>Learn more
                        about MASARY's work here.</a>
                </div>
                <div>
                    <img src={md_im2} className={'md-image-el'} alt={"a photo looking up at a wall covered with video projections of orange, red, and yellow abstractions which include diaganol lines, cactus, spills of paint. In the foreground is a man looking up at the wall with a cowboy hat and big scarf."}/>
                </div>
            </div>
            <MdFooter/>
        </Container>
    )
}

export default MdInfoPages;
