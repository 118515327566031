const MdEventEmitter = {
    events: {},
    dispatch: function (event, data) {
        if (!this.events[event]) return;
        this.events[event].forEach(callback => callback(data));
    },
    subscribe: function (event, callback) {
        if (!this.events[event]) this.events[event] = [];
        this.events[event].push(callback);
        return callback;
    },
    unsubscribe: function(event, callback) {
        if(!this.events[event]) return;
        let newEvents = this.events[event].filter(a => a!==callback);
        this.events[event] = newEvents;
    }
}

export default MdEventEmitter;
