import MdEventEmitter from "../../LayoutComps/MdEventEmitter";

const MdMetronomeWorkerCode = () => {
    let timerID = null;
    let interval = 100;
    onmessage = (e) => {
        if (e.data === 'start') {
            console.log("starting metronome worker");
            timerID = setInterval(() => {
                postMessage('tick')
            }, interval);
        } else if (e.data.interval) {
            interval = e.data.interval;
            if (timerID) {
                clearInterval(timerID);
                timerID = setInterval(() => {
                    postMessage('tick')
                }, interval);
            }
        } else if (e.data === 'stop') {
            console.log("stopping metronome worker");
            clearInterval(timerID);
            timerID = null;
        }
    }
};

let code = MdMetronomeWorkerCode.toString();
code = code.substring(code.indexOf('{') + 1, code.lastIndexOf('}'));

const blob = new Blob([code], {type: 'application/javascript'});
const worker_script = URL.createObjectURL(blob);


class MdMetronome {
    constructor(_audioContext,
                _currentComposition) {
        this.audioContext = _audioContext;
        this.unlocked = true;

        this.isPlaying = false;
        this.isPaused = false;
        this.allowLoop = true;

        this.startTime = null;
        this.current16thNote = null;
        this.tempo = 120.0;
        this.lookahead = 5.0; //TODO: this value is important. Come back. Was set to 25. Then 125.

        this.loopLength = 8;
        this.measureLength = 16 // in sixteenth notes
        this.currentMeasure = 0;
        this.scheduleAheadTime = 0.1;
        this.nextNoteTime = 0.0;
        this.timerWorker = new Worker(worker_script);
        this.currentComposition = _currentComposition;

        MdEventEmitter.subscribe('compLengthAdjust', data => {
            console.log(`comp length adjusted in metronome: ${data.newLength}`);
            this.measureLength = data.newLength;
        });
    }

    updateCompState(newCompState) {
        this.currentComposition = newCompState;
    }

    dispatchCurrent16thNote(current16thNote) {
        MdEventEmitter.dispatch('transportPositionUpdate', {
            position: this.current16thNote,
            measureLength: this.measureLength,
            currentMeasure: this.currentMeasure,
            loopLength: this.loopLength,
            fxIdx: (this.currentMeasure * this.measureLength) + this.current16thNote,
        });
    }

    nextNote() {
        let secondsPerBeat = 60.0 / this.tempo;
        this.nextNoteTime += 0.25 * secondsPerBeat;
        this.current16thNote++;

        this.dispatchCurrent16thNote(this.current16thNote);

        if (this.current16thNote === this.measureLength) {
            this.current16thNote = 0;
            this.currentMeasure++;
            if (this.currentMeasure === this.loopLength) {
                if (this.allowLoop) {
                    this.currentMeasure = 0;
                } else {
                    this.stop();
                }

            }
        }
    }

    scheduleNote(beatNumber, time) {
        for (let track in this.currentComposition) {
            for (let i in this.currentComposition[track]) {
                let tBeat = this.currentComposition[track][i][beatNumber];
                if (tBeat) {
                    MdEventEmitter.dispatch('sample_trigger', {triggerId: parseInt(i) + (12 * track)});
                }
            }
        }
    }

    scheduler() {
        while (this.nextNoteTime < this.audioContext.currentTime + this.scheduleAheadTime) {
            this.scheduleNote(this.current16thNote, this.nextNoteTime);
            this.nextNote();
        }
    }

    setPlayState(newPlayState) {
        this.isPlaying = newPlayState;

        if (this.isPlaying) {
            this.current16thNote = 0; // Restart transport
            this.currentMeasure = 0;
            this.nextNoteTime = this.audioContext.currentTime;
            this.timerWorker.postMessage('start');
        } else {
            this.isPaused = false;
            this.timerWorker.postMessage('stop');
        }
    }

    restartTransport() {
        if (this.isPlaying) {
            this.timerWorker.postMessage('stop');
            this.currentMeasure = 0;
            this.current16thNote = 0;
            this.nextNoteTime = this.audioContext.currentTime;
            this.timerWorker.postMessage('start');
        }
    }

    play() {
        if (this.isPlaying) {
            return;
        }
        if (!this.isPaused) {
            this.current16thNote = 0;
            this.currentMeasure = 0;
        }
        if (this.isPaused) {
            this.isPaused = false;
        }
        this.nextNoteTime = this.audioContext.currentTime;
        this.timerWorker.postMessage('start');
    }

    pause() {
        this.isPlaying = false;
        this.isPaused = true;
        this.timerWorker.postMessage('stop');
    }

    stop() {
        this.isPlaying = false;
        this.isPaused = false;
        this.timerWorker.postMessage('stop');
    }
}

export default MdMetronome;
