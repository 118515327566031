import React from 'react';
import Slider from "@material-ui/core/Slider";
import { withStyles } from "@material-ui/core/styles";
import MdEventEmitter from "../MdEventEmitter";

const styledBy = (property, mapping) => (props) => mapping[props[property]];

const mixerSlider = {
    root: {
        color : styledBy('color', {
            0: "rgb(63, 193, 209)",
            1: "rgb(90, 90, 255)",
            2: "rgb(219, 76, 56)"
        }),
        padding: "0 2% !important",
        width: "4.333333% !important"
    },
    rail: {
        width: "10% !important",
        opacity: "0.18",
        'border-radius': '0%',
    },
    track: {
        width: "10% !important",
        opacity: "1.0",
        "border-radius": "0%",
    },
    thumb: {
        width: "80%",
        height: "10px",
        "margin-bottom": "-6px !important",
        "margin-left": "-35% !important",
        "border-radius": "0%",
        "background-image": styledBy('color', {
            0: "linear-gradient(rgb(63, 193, 209), rgb(133, 255, 229))",
            1: "linear-gradient(rgb(90, 90, 255), rgb(120, 120, 255))",
            2: "linear-gradient(rgb(219,76,57), rgb(229, 96, 77))"
        }),
        "border-color": styledBy('color', {
            0: "rgb(63, 193, 209)",
            1: "rgb(90, 90, 255)",
            2: "rgb(219, 76, 56)"
        }),
        "border-width": "1px",
        "border-style": "solid",
    },
    mark: {
        opacity: 0.5,
        backgroundColor: styledBy('color', {
            0: "rgb(63, 193, 209)",
            1: "rgb(90, 90, 255)",
            2: "rgb(219, 76, 56)"
        }),
    },
    markActive: {
        backgroundColor: "#000000",
        opacity: 0.5,
    }
}

const StyledSlider = withStyles(mixerSlider)(({ classes, color, ...other }) => (
    <Slider classes={classes} {...other} />
));

const MdMixerUi = (props) => {
    return (
        <div className={"md-mixer-div"}>
            {props.mixerState.map((val, idx) => {
                return <StyledSlider
                    color={props.currentPack}
                    step={10}
                    marks
                    orientation={"vertical"}
                    value={val}
                    key={idx}
                    onChange={(e,nv) => {
                        if (props.mixerState[idx] !== nv)
                        {
                            //TODO: there should be a better (faster) way to handle this to increase speed on mobile.
                            //  come back to this later.
                            let t = [...props.mixerState];
                            t[idx] = nv;
                            props.setMixerState(t);
                            MdEventEmitter.dispatch("mixerStateChange", { pack: props.currentPack, idx: idx, newVal: nv }); //TODO: dispatch event to be grabbed by media engine. May want to reconsider this approach.
                        }
                    }}
                    aria-label={`Adjust volume / brightness for sample ${(props.currentPack * 12) + idx}`}
                />
            })}
        </div>
    )
}

export default MdMixerUi;
