import React, {useRef} from 'react';
import { Container } from '@material-ui/core';
import './infoStyles.css';
import bannerImage from './images/etc/MD_HC_header.png';
import MdFooter from "./MdFooter";

// import SampleDetails from './SampleDetails/SampleDetails';
import SampleDetails_HC from "./SampleDetails/SampleDetails_HC";

const MdHc = () => {
    const mapRef = useRef(null);

    return (
        <Container maxWidth={"md"} className={"md-info-pages-container"} role={"main"}>
            <div>
                <img src={bannerImage} alt={"Massively Distributed @ Holy Cross"} className={'md-image-el-hc'} role={"banner"}/>
            </div>
            <div className={"md-info md-info-just"}>
                <div className={"md-info-div"}>
                    Massively Distributed was brought to Worcester, MA, in Spring 2021, presented by the Arts Transcending Borders (ATB) initiative at the College of the Holy Cross.
                </div>
                <div className={"md-info-div"}>
                    Arts Transcending Borders is an initiative designed to enhance the role of the arts in every aspect of the Holy Cross experience by infusing the arts
                    into students' academic lives and creating new opportunities throughout the curriculum and the community to cross cultural, geographic, and disciplinary boundaries.
                </div>
                <div className={"md-info-div"}>
                    <a href={"https://www.holycross.edu/departments-and-programs/arts-transcending-borders-atb"}>More about Arts Transcending Borders (ATB) at the College of the Holy Cross can be found here.</a>
                </div>

                <div className={"md-info-div"}>
                    MASARY has been working with ATB and the Natural World Cluster of the Montserrat first-year program at the College in a way of sharing and collaborating on this type of artwork.
                    The collaborative project has started with the participation of 6 professors and over 100 first-year students, and will continue as the rest of the College community is invited to create and compose with MD Holy Cross.
                </div>

                <div className={"md-info-div"}>
                    Students, professors and staff recorded and sent MASARY hundreds of sound and visual samples. From the cruncy sound of stepping into the snow to the visual image of the college campus, the samples are the site-specific contents that help drive this piece.
                </div>

                <div className={"md-info-div"}>
                    Participants on campus will create compositions and submit to the database for about 4 weeks, starting in mid-March, 2021. MASARY will then aggregate all submissions into meta-compositions, creating longer form presentation pieces that are the combined results of public works.
                    The final result will be presented via large scale video project and amplified sound outdoors on the Holy Cross campus in late April; time and place to be determined.
                </div>
            </div>

            <h1>About the Samples</h1>
            <div className={"md-info-div md-info-just"}>
                All of the samples used in MD at Holy Cross were recorded by Holy Cross students, faculty, and staff in and around the College of the Holy Cross area in Worcester, MA.
            </div>
            <div className={"md-info-div md-info-just"}>
                Hundreds of recordings were sent to MASARY in order to construct 36 total audio / visual samples across 3 packs within MD. These raw samples were then edited and prepared by the MASARY team before being loaded into the MD webapp.
            </div>
            <h2>Sample Notes and Artistic Treatment</h2>
            <SampleDetails_HC/>
            <MdFooter />
        </Container>
    )
}

export default MdHc;
