import React, {useEffect, useState} from 'react';
import { Container } from "@material-ui/core";
import { useHistory } from 'react-router-dom';

function importAll(r) {
    return r.keys().map(r);
}

const images = importAll(require.context('./images/Slideshow_10232020', false, /\.(png|jpe?g|svg)$/));

const MdSlideshow = (props) => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const history = useHistory();
    const nextSlide = () => {
        setCurrentSlide(currentSlide + 1);
    }

    console.log(props.lastPage);

    useEffect(() => {
        if (currentSlide >= images.length) {
            if (props.lastPage) {
                history.push(props.lastPage);
            } else {
                history.push('/');
            }
        }
    }, [currentSlide]);

    return (
        <Container maxWidth={"md"} className={"md-info-pages-container"}>
            <img src={images[currentSlide]} className={'md-slideshow-img'} onClick={nextSlide} alt={"massively distributed how-to slideshow"}/>
        </Container>
    )
}

export default MdSlideshow;
