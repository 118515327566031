import React from 'react';
import {Container} from "@material-ui/core";

import './infoStyles.css';
import bannerImage from './images/MD_MASARY_About_header.jpg';
import MdFooter from "./MdFooter";
// import im1 from './images/MASARY_spread_1.jpg';
import im1 from './images/Postcard_Masary_Standard_vB copy.jpg';
import im2 from './images/md_Sequencer Grid_landing page_2.jpg';

import Grid from "@material-ui/core/Grid";

const makeCredit = (creditName, creditTitle) => {
    return {creditName, creditTitle};
}

const MdCredit = (props) => {
    return (
        <Grid item xs={6}>
            <div className={"credit-entry"}>
                <div>{props.creditName}</div>
                <span>{props.creditTitle}</span>
            </div>
        </Grid>
    )
}


const MdAbout = () => {
    return (
        <Container maxWidth={"md"} className={"md-info-pages-container"}>
            <div>
                <img src={bannerImage} alt={"About Massively Distributed"} className={"md-image-el"}/>
            </div>
            <div className={"md-info md-info-just"}>
                <div className={"md-info-div"}>
                    {/*MASARY Studios is an interdisciplinary artist collective reconsidering environments through*/}
                    {/*site-specific installations using sound, light, interactivity, and performance. Their artwork often*/}
                    {/*relies on location and / or interaction to be realized.*/}
                    MASARY Studios is an transdisciplinary artist collective reconsidering environments through
                    site-specific installations using sound, light, interactivity, research and performance. Their
                    artwork often relies on location and / or interaction to be realized.
                </div>
                <div className={"md-info-div"}>
                    {/*For the past 5 years, MASARY has been exploring the relationship between visual and sound elements*/}
                    {/*through performances, interactivity and installations. This research has spawned variations on*/}
                    {/*instruments and interfaces, and developed new languages and technologies, all in the effort to*/}
                    {/*synchronize and relate audio and visual expressions.*/}
                    Since 2015, MASARY has been exploring the relationship between visual and sound elements through
                    their work. This research has spawned variations on instruments and interfaces, and facilitated the
                    development of new languages and technologies, all in the effort to synchronize and relate audio and
                    visual expressions.
                </div>
                <div className={"md-banner-image-div"}>
                    <img src={im1} alt={""}/>
                </div>
                <div className={"md-info-div"}>
                    {/*Massively Distributed (MD) is a major culmination of this work and expression. It is an interactive*/}
                    {/*and site-specific artwork. By using samples recorded in the presenting city, the instrument offers*/}
                    {/*the opportunity to play and create with elements of the city’s sonic and visual landscapes.The*/}
                    {/*locality of the sounds and visuals allow MD to shift shape and form based where it is being*/}
                    {/*presented, evolving as it maintains a site specificity. .*/}
                    Massively Distributed (MD) is a major culmination of this work and expression. It is an interactive
                    and site-specific artwork that engages presenters and the public in an exploration and discovery of
                    their city in a new way. By using samples recorded in the presenting city, the web-app instrument
                    offers the public an opportunity to play and create with elements of the city’s sonic and visual
                    landscapes. These creations are then arranged together and activated through large scale public
                    projection and sound installation within the participating location. The locality of the sounds,
                    visuals, and installation allow MD to form and shape shift based on where it is being presented,
                    evolving as it maintains a site specificity.
                </div>
                <div className={'md-info-div'}>
                    MD is both a site specific distributed artwork that can be accessed through personal devices, and a
                    large-scale public installation featuring community-created compositions.
                </div>
                <div className={"md-info-div"}>
                    MD cannot be activated without community interaction. The piece is prepared through the interface
                    and samples, and is only truly realized once it is engaged by the public via the <a
                    href={'app'}>web-app</a>. MD is
                    broadcast out to the community in which it is being presented, inviting the public to perform, play,
                    interact, build, and submit compositions created within the web-app.
                </div>
                {/*<div className={"md-info-div"}>*/}
                {/*    Once submissions are received and MASARY has completed the “meta-composing” - users can expect their compositions to be featured in combination with others through the installation series. The multi-part installation includes the meta-compositions video projected at the scale of buildings and pushed through a large speaker system.*/}
                {/*</div>*/}
                <div className={"md-info-div"}>
                    MASARY then “meta-composes” with user-submitted compositions&mdash;organizing the public creations
                    into
                    larger aggregate compositions based on the characteristics of each. These compositions are then
                    presented through large-scale projection. Below is a ‘meta-composition’ created using submissions by
                    ArtWorks in Cincinnati, OH.
                </div>
                <div className={"md-trailer"}>
                <iframe src="https://player.vimeo.com/video/754065272?h=74ffe4a217&title=0&byline=0&portrait=0" width="640" height="480" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>

                </div>
                <div className={"md-banner-image-div"}>
                    <img src={im2} alt={"A designed graphic that is a horizontal white outlined grid. The grid has 8 random orange cubes filling some of the grid. The image is in refrence to a beat sequencer and the sequencer built into the Massively Distributed web-app"}/>
                </div>
                <div className={"md-credits-div"}>
                    <h1 style={{"text-align": "center", "paddingBottom": "1em"}}>MASARY Studios</h1>
                    <Grid container spacing={6} justify={"space-evenly"}>
                        <MdCredit creditName={"Sam Okerstrom-Lang"} creditTitle={"Co-Founder and Principal"}/>
                        <MdCredit creditName={"Ryan Edwards"} creditTitle={"Co-Founder and Principal"}/>
                        <MdCredit creditName={"Caleb Hawkins"} creditTitle={"Design Director"}/>
                        <MdCredit creditName={"Jeremy Stewart, PhD"}
                                  creditTitle={"Technical Director and Media Artist"}/>
                        <MdCredit creditName={"Grace Fo"} creditTitle={"Studio Manager"}/>
                        <MdCredit creditName={"Odie DeSmith"} creditTitle={"Technical Assistant"}/>
                        <MdCredit/>
                    </Grid>
                </div>

            </div>
            <div className={"md-info-div"}>
                We'd like to thank the community and team that helped develop and debut Massively Distributed in
                2020: Canal Convergence,
                Scottsdale Public Art, Rob Petit, Mac Pierce, Matt Pollock, Meg Anderson, Zumix, and Shadow Mountain
                High.
                {/*<h2 style={{"text-align": "center"}}>Additional Thanks</h2>*/}
                {/*<Grid container spacing={6} justify={"space-evenly"}>*/}
                {/*    <MdCredit creditName={"Scottsdale Public Art"} creditTitle={"Debut Commissioning Partner"}/>*/}
                {/*    <MdCredit creditName={"Matt Pollock"} creditTitle={"Design Consultant"}/>*/}
                {/*    <MdCredit creditName={"Rob Petit"} creditTitle={"Quality Assurance"}/>*/}
                {/*    <MdCredit creditName={"Geoffrey Nielson"} creditTitle={"Quality Assurance"}/>*/}
                {/*    <MdCredit creditName={"Mac Pierce"} creditTitle={"Quality Assurance"}/>*/}
                   {/* <MdCredit creditName={"Meg Anderson"} creditTitle={"Quality Assurance"}/> */}
                {/*    <MdCredit creditName={"Shadow Mountain High School"} creditTitle={"Early Engagement"}/>*/}
                {/*    <MdCredit creditName={"ZUMIX"} creditTitle={"Early Engagement"}/>*/}
                {/*</Grid>*/}
            </div>
            <MdFooter/>
        </Container>
    )
}

export default MdAbout;
