const config = {
    'server_ip': '172.104.15.130', //linode production server
    'api_ip': '172.104.15.130', // linode production server
    'reverb_impulse_response': '/media/impulseResponses/LadyChapelStAlbansCathedral.m4a',
    'pack_ref_paths': [
        '/media/PREPPED/oh/pack1/v3/packRef.json',
        '/media/PREPPED/oh/pack2/v3/packRef.json',
        '/media/PREPPED/oh/pack3/v3/packRef.json',
    ],
    'ui_settings':
        {
            'videoplayer':
                {
                    'aspect_ratio': 0.75,
                },
            'mixer':
                {
                    'default_volume': 70,
                },
            'effects':
                {
                    'autorefresh':
                        {
                            'enable': false,
                            'timeout': 30,
                            'alpha': 0.025,
                        }
                }
        },
    'allowed_values':
        {
            'tempo': [{value: 60}, {value: 74}, {value: 90,}, {value: 120,}, {value: 180,}],
            'compLength': [{value: 9}, {value: 10}, {value: 11}, {value: 12}, {value: 13}, {value: 14}, {value: 15}, {value: 16}],
        },
    'submission_version': '1.3.3',
}

export default config;
