class TestFilter {
    constructor(audioCtx) {
        this.lpNode = audioCtx.createBiquadFilter();
        this.lpNode.type = "lowpass";
        this.minFreq = 150;
        this.maxFreq = 20000;
        this.minQ = 1;
        this.maxQ = 18;
        this.lpNode.frequency.value = this.maxFreq;
        this.lpNode.Q.value = this.minQ;
    }
    get source() {
        return this.lpNode;
    }
    get destination() {
        return this.lpNode;
    }

    set x(value) {
        this.lpNode.frequency.value = (Math.pow(value, 5) * (this.maxFreq - this.minFreq)) + this.minFreq;
    }

    set y(value) {
        this.lpNode.Q.value = ((1 - value) * (this.maxQ - this.minQ)) + this.minQ;
    }

    getDefaultPosition() {
        return {x: 0.0, y: 1.0};
    }

    clear() {
        this.lpNode.frequency.value = this.maxFreq;
        this.lpNode.Q.value = this.minQ;
    }
}

export default TestFilter;
