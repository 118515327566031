import React, {useState} from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {useHistory} from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import MdApp from "./MassivelyDistributed/MdApp";
import MdAz from "./MassivelyDistributed/MdInfoWebsite/MdAz";
import MdOh from './MassivelyDistributed/MdInfoWebsite/MdOh';

import MdAbout from "./MassivelyDistributed/MdInfoWebsite/MdAbout";
import MdLearning from "./MassivelyDistributed/MdInfoWebsite/MdLearning";
import MdSlideshow from "./MassivelyDistributed/MdInfoWebsite/MdSlideshow";
import MdInfoPages from "./MassivelyDistributed/MdInfoWebsite/MdInfoPages";

import { Drawer } from '@material-ui/core';
import { List, ListItem, ListItemText } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { Fab } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MenuIcon from '@material-ui/icons/Menu';
import MdHc from "./MassivelyDistributed/MdInfoWebsite/MdHc";


const useStyles = makeStyles((theme) => ({
    fab: {
        backgroundColor: "rgba(125, 125, 125, 0.6)",
        position: "fixed",
        top: theme.spacing(2),
        left: theme.spacing(2),
    },
}));

const makeDrawerStyle = makeStyles(theme => ({
    root: {
        "& .MuiPaper-root": {
            backgroundColor: "#111"
        }
    }
}));

const availablePages =
    [
        {name: 'Home', link: '/'},
        {name: 'Cincinnati', link: '/cincinnati'},
        {name: 'Holy Cross', link: '/holycross'},
        {name: 'AZ', link: '/az'},
        {name: 'About', link: '/about'},
        {name: "App", link: '/app'},
        {name: 'Learn More', link: '/learning'},
        {name: 'How-To', link: '/how-to'}
    ];

const TopLevelApp = () => {
    const classes = useStyles();
    const [drawerState, setDrawerState] = useState(false);
    const drawerStyle = makeDrawerStyle();

    const getDrawer = () => {
        return (
            <>
                <Drawer className={drawerStyle.root} anchor={'left'} open={drawerState} onClose={() => {setDrawerState(false)}}>
                    <List>
                        {availablePages.map((i) => (
                            <ListItem button key={i.name} onClick={() => {setDrawerState(false)}}>
                                <Link to={i.link} className={"drawer-link"}>
                                    <ListItemText primary={i.name} />
                                </Link>
                            </ListItem>
                        ))}
                    </List>
                </Drawer>
            </>
        )
    }

    return (
        <>
            <Router>
                {getDrawer()}
                <Switch>
                    <Route path={'/app'}>
                        <MdApp className={"the-app"}/>
                    </Route>

                    <Route path={"/cincinnati"}>
                        <MdOh />
                    </Route>

                    <Route path={"/az/app"}>
                        <Redirect to={"/app"} />
                    </Route>

                    <Route path={"/holycross"}>
                        <MdHc />
                    </Route>

                    <Route path={"/az"}>
                        <MdAz />
                    </Route>
                    <Route path={"/about"}>
                        <MdAbout />
                    </Route>
                    <Route path={"/learning"}>
                        <MdLearning />
                    </Route>
                    <Route path={"/how-to"}>
                        <MdSlideshow />
                    </Route>
                    <Route path={'/'}>
                        <MdInfoPages />
                    </Route>
                </Switch>
            </Router>
            <Fab size={"small"} color={"primary"} aria-label={"add"} className={classes.fab} onClick={() => {
                setDrawerState(true);
            }}>
                <MenuIcon />
            </Fab>
        </>
    )
}

export default TopLevelApp;
